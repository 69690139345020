import React from 'react'
import PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { GlobalStyle } from '../../styles/global'

// GlobalLayout is always mounted to React dom even when pages are changed.
// In the future, this is the correct place to implement page transitions
const GlobalLayout = ({ children }) => (
  <React.Fragment>
    <ParallaxProvider>
      <MDXProvider>
        <GlobalStyle />
        {children}
      </MDXProvider>
    </ParallaxProvider>
  </React.Fragment>
)

GlobalLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default GlobalLayout
