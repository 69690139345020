const variables = {
  // TODO: breakpoints are not yet correctly configured for react-styled-flexboxgrid
  // theming is done in src/html.js but the library doesn't catch the config correctly
  // The queries below are now in sync with the react-styled-flexboxgrid library definitions.
  // They can be changed only after we get these ones syncing to the library
  media: {
    xs: 'screen and (min-width: 0px)',
    sm: 'screen and (min-width: 48em)',
    md: 'screen and (min-width: 64em)',
    lg: 'screen and (min-width: 75em)',
    xlg: 'screen and (min-width: 100em)'
  },

  fonts: {
    body: '\'Source Sans Pro\', serif',
    header: '\'Space Mono\', Courier, sans-serif',
  },

  headerScale: {
    h1Scale: 4.25,
    h2Scale: 3.5,
    h3Scale: 2.75,
    h4Scale: 2,
    h5Scale: 1.35,
    h6Scale: 1.2,
    // In small screens, scale header fonts with this ratio to make them better fit to viewport
    smallScreenHeaderScaleFactor: 0.66,
    // Header margins are by default equal to their font-size. E.g. If h1 is 4.25rem,
    // it has 4.25rem margin-top and margin-bottom. This factor will will scale as wanted.
    // If the margin feels too large, we can scale it down to e.g. 0.7 which makes margin-bottom
    // 0.7 * 4.25rem = 2.975rem
    headerMarginTopFactor: 1.1,
    headerMarginBottomFactor: 0.7
  },

  maxContentWidth: 1400, // px
  navBarHeight: 90,

  // Main color name (medium) has been taken from: http://chir.ag/projects/name-that-color/
  colors: {
    jade: {
      darker: '#004121',
      dark: '#007e44',
      medium: '#00b06a',
      light: '#57d684',
      lighter: '#b0f1b5',
    },
    carnationPink: {
      darker: '#332721',
      dark: '#996467',
      medium: '#ffa9d3',
      light: '#ffcbd8',
      lighter: '#ffeff0',
    },
    silverTree: {
      darker: '#205341',
      dark: '#3f896e',
      medium: '#64b99a',
      light: '#88debf',
      lighter: '#baf5e0',
    },
    sunsetOrange: {
      darker: '#690a01',
      dark: '#b42515',
      medium: '#fd4f39',
      light: '#ffa273',
      lighter: '#ffe9cb',
    },
    bondiBlue: {
      darker: '#001526',
      dark: '#006a6d',
      medium: '#00a9b0',
      light: '#6ad7c6',
      lighter: '#d6f2f5',
    },
    pearlBrush: {
      darker: '#302e2c',
      dark: '#8d847f',
      medium: '#e7dbd3',
      light: '#f5f1e6',
      lighter: '#ffffff',
    },
    azureRadiance: {
      darker: '#013d7a',
      dark: '#015abe',
      medium: '#0077ff',
      light: '#65b2ff',
      lighter: '#c9e7ff',
    },
    eunry: {
      darker: '#563b3e',
      dark: '#926b71',
      medium: '#cb9ea5',
      light: '#e4b6bd',
      lighter: '#f5d5da',
    },
    oracle: {
      darker: '#00282f',
      dark: '#004548',
      medium: '#357972',
      light: '#a1c9b7',
      lighter: '#c5e4c7',
    },
  }
}

// Add convenience aliases which map to bootstrap color naming
variables.colors.primary = variables.colors.carnationPink.medium
variables.colors.secondary = variables.colors.carnationPink.dark
variables.colors.success = variables.colors.jade.light
variables.colors.darkSuccess = variables.colors.jade.medium
variables.colors.danger = variables.colors.sunsetOrange.medium
variables.colors.warning = variables.colors.sunsetOrange.light
variables.colors.info = variables.colors.bondiBlue.medium
variables.colors.light = variables.colors.silverTree.lighter
variables.colors.dark = variables.colors.bondiBlue.darker
variables.colors.muted = variables.colors.pearlBrush.dark
variables.colors.white = '#fff'
variables.colors.transparent = 'transparent'

export default variables
