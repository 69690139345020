import styled from 'styled-components'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 18px 40px 18px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  background: #fcfcfc;
  color: #686868;
  border-radius: 2px 5px 5px 2px;
  border: 1px solid #eee;
  border-left: 3px solid #333;

  ${props => props.type === 'info' && `
    border-left: 3px solid ${variables.colors.azureRadiance.light};
  `}

  ${props => props.type === 'warning' && `
    border-left: 3px solid ${variables.colors.sunsetOrange.light};
  `}

  ${props => props.type === 'error' && `
    border-left: 3px solid ${variables.colors.sunsetOrange.medium};
  `}
`

export const Content = styled.div`
  margin: 0;
  padding: 0;

  /* Remove paddings coming from e.g. markdown content */
  *:last-child {
    margin: 0;
    padding: 0;
  }
`

export const Icon = styled.img`
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  margin: 0 16px 0 18px !important;
  padding: 0 !important;
`
