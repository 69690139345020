import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import Img from 'gatsby-image'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import variables from '../../styles/variables'

export const EntryRow = styled(Row)`
  display: flex;
  margin: 100px 0;
`

export const EntryCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const Container = styled.div`
  max-width: ${variables.maxContentWidth + 400}px;
  padding: 40px 20px 60px 20px;
  color: white;

  & > ${EntryRow}:nth-child(even) {
    flex-direction: row-reverse;
  }
`

export const StyledImage = styled(Img)`
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  width: 100%;
  @media ${variables.media.sm} {
    width: 400px;
  }
  @media screen and (min-width: 85em) {
    width: 500px;
  }
  @media screen and (min-width: 95em) {
    width: 600px;
  }
`

export const StoryHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
`
