import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'
import { AnimationWrapper } from '..'

const propTypes = {
  // https://github.com/yannickcr/eslint-plugin-react/issues/904
  animation: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default class ApiEndpointIntro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pause: true,
    }
    this.toggleAnimation = this.toggleAnimation.bind(this)
  }

  toggleAnimation() {
    this.setState((prevState) => {
      return {
        pause: !prevState.pause
      }
    })
  }

  render() {
    const { pause } = this.state
    const { animation, header, text, url } = this.props
    return (
      <styles.Container
        onMouseEnter={this.toggleAnimation}
        onMouseLeave={this.toggleAnimation}
      >
        <styles.AnimationContainer>
          <AnimationWrapper
            animation={animation}
            hoverPause={pause}
            hoverOnly
          />
        </styles.AnimationContainer>

        <styles.Right>
          <styles.Header>
            {header}
          </styles.Header>
          <styles.IntroText>
            {text}
          </styles.IntroText>
          <styles.ReadMoreLink target="_blank" rel="noopener noreferrer" href={url}>
            Read more
            <styles.Chevron />
          </styles.ReadMoreLink>
        </styles.Right>
      </styles.Container>
    )
  }
}

ApiEndpointIntro.propTypes = propTypes
