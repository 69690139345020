import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
}

const defaultProps = {
  label: null,
  className: '',
}

const Checkbox = (props) => {
  const { children, label } = props
  const passProps = _.omit(props, ['label'])

  const inputElement = (
    <styles.Checkbox type="checkbox" {...passProps}>
      {children}
    </styles.Checkbox>
  )
  const labelElement = (
    <styles.Label>
      {inputElement}

      <styles.LabelText>
        {label}
      </styles.LabelText>
    </styles.Label>
  )
  return label ? labelElement : inputElement
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
