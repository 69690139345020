import styled from 'styled-components'
import variables from './variables'

export const TextWrapper = styled.div`
  max-width: ${variables.maxContentWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media ${variables.media.sm} {
    padding: 40px 40px;
  }
`

export const ContentStyles = styled.div`
  font-size: 1.15rem;
  line-height: 1.6;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  font-kerning: normal;
  color: #555;

  table {
    width: 100%;
    margin: 30px 0;
    overflow-x: auto;
    display: block;
    text-align: left;
    line-height: 1.45rem;
    border-collapse: collapse;
    font-size: 1rem;
  }
  th {
    border-bottom: 1px solid #eee;
    font-weight: 600;
    color: #777;
    background-color: #f9f9f9;
    border-radius: 2px;
  }
  th {
    padding: 10px 15px;
  }
  td {
    padding: 10px 15px;
    background-color: white;
  }
  tr {
    border-bottom: 1px solid #e8e8e8;
  }

  hr {
    margin: 2rem 0;
  }

  /* https://github.com/mrmrs/fluidity */
  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  blockquote {
    margin: 0;
    border-left: 5px solid #7a7a7a;
    font-style: italic;
    padding: 20px;
  }

  blockquote p {
    margin: 0 0 1rem 0;
  }

  p {
    max-width: 700px;
    line-height: 1.5;
    margin: 1.4rem 0;
  }

  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 3rem;
    margin-bottom: 0.725rem;
    color: hsla(0, 0%, 0%, 0.8);
    font-family: 'Source Sans Pro', 'Arial', sans-serif;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    line-height: 1.2;
    word-spacing: normal;
  }

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.3rem;
  }

  h6 {
    font-size: 1.3rem;
  }

  ul, ol {
    margin-left: 1.45rem;
    list-style-position: outside;
    list-style-image: none;
  }

  blockquote {
    margin-left: 1.45rem;
    margin-right: 1.45rem;
    font-size: 1.15rem;
    line-height: 1.45rem;
    border-left: 0.3625rem solid #eee;
    color: hsla(0, 0%, 0%, 0.65);
    padding: 0.5rem 0 0.5rem 1.2rem;
    font-style: italic;
    margin: 2rem 0 2rem 0;
  }

  hr {
    margin-bottom: calc(1.16rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
  }

  b, strong {
    font-weight: 700;
  }

  li {
    margin-bottom: calc(1.16rem / 2);
  }

  ol li, ul li {
    padding-left: 0;
  }

  li > ol {
    margin-left: 1.45rem;
    margin-bottom: calc(1.16rem / 2);
    margin-top: calc(1.16rem / 2);
  }

  li > ul {
    margin-left: 1.45rem;
    margin-bottom: calc(1.16rem / 2);
    margin-top: calc(1.16rem / 2);
  }

  blockquote *:last-child {
    margin-bottom: 0;
  }

  li *:last-child {
    margin-bottom: 0;
  }

  p *:last-child {
    margin-bottom: 0;
  }

  li>p {
    margin-bottom: calc(1.16rem / 2);
  }

  pre, code {
    font-family: 'Source Code Pro', Courier, sans-serif;
    font-size: 13px;
  }

  code {
    background-color: #f4f4f4;
    padding: 2px 6px;
    margin: 0 3px;
    border-radius: 2px;
    white-space: nowrap;
  }

  pre {
    border-radius: 4px;
    line-height: 1.45rem;
    background-color: #fafafa;
    padding: 10px;
    overflow: auto;
  }

  pre > code {
    background: none;
    white-space: pre;
    padding: 0;
    margin: 0;
  }

  code, kbd, samp {
    line-height: 1.5rem;
  }

  abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  blockquote cite {
    font-size: 1rem;
    line-height: 1.45rem;
    color: hsla(0, 0%, 0%, 0.8);
    font-style: normal;
    font-weight: 400;
  }

  blockquote cite:before {
    content: "— ";
  }

  .mermaid {
    margin: 30px 0;
  }
`

export const MdContainer = styled(ContentStyles)`
  margin: 0 auto;
  padding: 50px 20px;
  max-width: 100%;
  width: 45rem;
  margin: 0 auto 50px auto;
`

export const ColoredRectangleContainer = styled.div`
  margin: 30px auto 30px auto;
  padding: 65px 20px;

  @media ${variables.media.sm} {
    margin: 30px 3% 30px 3%;
    padding: 80px 100px;
    max-width: ${variables.maxContentWidth + 300}px;
  }
`
