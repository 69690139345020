import styled from 'styled-components'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading, Button as BaseButton } from '..'

export const IntroContainer = styled.div`
  width: 100%;
  background: ${variables.colors.oracle.dark};
  padding: 70px 0;
  position: relative;
`

export const IntroSection = styled(TextWrapper)`
  background: ${variables.colors.oracle.dark};
  color: white;
`

export const IntroHeader = styled(Heading)`
  ${headerStyles.headerLarge};
  word-wrap: nowrap;
  animation: slideUp 0.5s ease-in-out;
`

export const IntroTagline = styled.p`
  color: ${variables.colors.silverTree.medium};
  font-size: 1.2rem;
  margin-bottom: 40px;
  animation: slideUp 0.5s ease-in-out 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
  @media ${variables.media.sm} {
    max-width: 80%;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 50px;
  animation: fadeIn 0.5s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
`

export const IntroSectionSideSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`

export const IntroSectionBottomSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: -75px;
    left: 10%;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`
export const Button = styled(BaseButton)`
  margin-right: 15px;
`
