import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled(TextWrapper)`
  width: 100%;
  @media ${variables.media.sm} {
    padding: 40px 160px;
  }
`

export const SmallHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  color: ${variables.colors.jade.medium};
  font-size: 1rem;
  margin: 12px 0;
`

export const Header = styled(Heading)`
  ${headerStyles.headerMedium}
  color: ${variables.colors.silverTree.dark};
  margin: 12px 0;
`

export const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-bottom: 10px;
  }
`

export const SmallText = styled.div`
  font-size: .8rem;
  padding: 0;
  text-align: center;
  color: grey;

  a {
    text-decoration: underline;
    color: currentColor;
  }
`
