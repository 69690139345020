import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'
import dashLine from '../../../assets/dash-line.svg'
import { Section, Markdown } from '..'

const workflowItem = PropTypes.shape({
  smallHeader: PropTypes.string,
  bigHeader: PropTypes.string,
  text: PropTypes.string,
  imageUrl: PropTypes.string,
})

const propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(workflowItem),
}

const defaultProps = {
  items: []
}

const Dash = ({ i }) => (
  i % 2 === 0
    ? (
      <styles.DashLineContainer>
        <styles.DashLine src={dashLine} alt="" />
      </styles.DashLineContainer>
    ) : (
      <styles.DashLineContainer>
        <styles.DashLineReverse src={dashLine} alt="" />
      </styles.DashLineContainer>
    )
)

const Workflow = ({ header, items }) => {
  return (
    <styles.WorkflowContainer>
      <styles.SectionContainer>
        <styles.SectionRow>
          <styles.SectionCol sm={1} xs={0} />
          <styles.SectionCol sm={5} xs={12}>
            <styles.SectionSmallHeader>
              {header}
            </styles.SectionSmallHeader>
          </styles.SectionCol>
        </styles.SectionRow>

        <Section>
          {items.map((item, i) => (
            <Fragment key={item.bigHeader}>
              <styles.StepRow reverse={i % 2 === 1}>
                <styles.SectionCol sm={1} xs={0} />
                <styles.SectionCol sm={5} xs={12}>
                  <styles.StepSmallHeader>
                    {item.smallHeader}
                  </styles.StepSmallHeader>
                  <styles.StepBigHeader>
                    {item.bigHeader}
                  </styles.StepBigHeader>
                  <styles.StepText>
                    <Markdown>
                      {item.text}
                    </Markdown>
                  </styles.StepText>
                </styles.SectionCol>
                <styles.SectionCol sm={1} xs={0} />
                <styles.SectionCol sm={5} xs={12} reverse={i % 2 === 1}>
                  <styles.DataSourceImage src={item.imageUrl} alt="" />
                </styles.SectionCol>
              </styles.StepRow>

              { (i !== items.length - 1) && <Dash i={i} /> }

            </Fragment>
          ))}
        </Section>
      </styles.SectionContainer>
    </styles.WorkflowContainer>
  )
}

Workflow.propTypes = propTypes
Workflow.defaultProps = defaultProps
export default Workflow
