import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/octicons'
import { Link as BaseLink } from 'gatsby'
import Img from 'gatsby-image'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import variables from '../../styles/variables'

export const BlogCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Link = styled(BaseLink)`
  &:hover {
    text-decoration: none;
  }
`

export const BlogImg = styled(Img)`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 3px;
`
export const BlogTitle = styled(Heading)`
  ${headerStyles.headerXSmall};
  font-family: ${variables.fonts.body};
  font-weight: 600;
  color: #404040;
  text-decoration: none !important;
  margin: 10px 0 5px 0 !important;
`
export const BlogSummary = styled.p`
  margin: 0.5rem 0;
  color: #404040;
`
export const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`
export const Tag = styled.p`
  margin: 0 5px 0 0;
  color: #9A9A9A;
`

export const ReadMoreLink = styled(BaseLink)`
  margin-top: auto;
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`
