import styled from 'styled-components'
import posed from 'react-pose'
import variables from '../../styles/variables'

const Frame = posed.div({
  init: {
    applyAtEnd: { display: 'none' },
    opacity: 0
  },
  zoom: {
    applyAtStart: { display: 'block' },
    opacity: 1
  }
})

const transition = {
  duration: 400,
  ease: [0.08, 0.69, 0.2, 0.99]
}

const Image = posed.img({
  init: {
    width: 'auto',
    height: 'auto',
    position: 'static',
    transition,
    flip: true
  },
  zoom: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition,
    flip: true
  }
})

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-center: center;
  margin: 80px 0;
  padding: 0;
`
export const ImageWrapper = styled.div`
  height: ${props => (props.isImgZoomed ? '100vh' : 'auto')};
`
export const BlogImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  cursor: ${props => (props.isImgZoomed ? 'zoom-out' : 'zoom-in')};
  display: block;
  margin: auto;

  ${props => props.isImgZoomed && `
    z-index: 999;
  `}
`
export const ImageFrame = styled(Frame)`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: white;
  transform: translateZ(0);
`
export const Caption = styled.span`
  text-align: center;
  font-size: 0.85rem;
  margin: 1.5rem 0 0 0;
  @media ${variables.media.sm} {
    margin: 1.8rem 0 0 0;
  }
`

export const Credit = styled.span`
  text-align: center;
  font-size: 0.8rem;
  margin: 0.7rem 0 0 0;
`
