import styled from 'styled-components'
import { ChevronDown } from '@styled-icons/octicons'
import { slide } from 'react-burger-menu'
import { CustomLink, HoverMenu as BaseHoverMenu, Button as BaseButton } from '..'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  height: ${variables.navBarHeight}px;
  background: ${props => (props.backgroundColor ? props.backgroundColor : variables.colors.silverTree.darker)};
  user-select: none;
  box-shadow: none;

  ${props => props.slideOut && `
    position: fixed;
    z-index: 999;
    top: 0;
    background: ${(props.backgroundColor ? props.backgroundColor : variables.colors.oracle.dark)};
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    transition: transform 300ms ease, box-shadow 200ms ease, visibility 200ms ease;
    transform: translateY(-${variables.navBarHeight}px);
    visibility: hidden;
  `}

  ${props => props.showSlideOut && `
    transform: translateY(0px);
    visibility: visible;
  `}
`

export const HoverMenu = styled(BaseHoverMenu)`
  ${props => props.slideOut && `
    visibility: hidden;
  `}

  ${props => props.showSlideOut && `
    visibility: visible;
  `}
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${variables.maxContentWidth}px;
  margin: 0 auto;
`

export const LogoContainer = styled.div`
  padding: 30px;
`

export const LinkWrapper = styled.div`
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.textColor};
  justify-content: flex-end;
`
export const Logo = styled.img`
  margin: 0;
  padding: 0;
  width: 80px;
`

export const LinkList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li a, span {
    color: ${props => props.textColor};
  }
  li a:hover {
    color: ${props => props.textColor};
  }
  li:not(:last-child) {
    margin-right: 30px;
  }
`
export const Padded = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`

export const ExpandableMenuItem = styled.div`
  position: relative;
  margin-right: 17px;
`

export const ExpandableMenuItemChevron = styled(ChevronDown)`
  position: absolute;
  top: calc(50% + 2px);
  transform: translateY(-50%);
  right: -20px;
  width: 15px;
  height: 15px;
`

export const BurgerMenu = styled(slide)`
  height: 100vh !important;
  color: ${variables.colors.pearlBrush.lighter};
  background: ${variables.colors.oracle.dark};
`

export const BurgerMenuLink = styled(CustomLink)`
  padding-bottom: 20px;
  color: currentColor !important;
`

export const BurgerMenuLinkSmall = styled(CustomLink)`
  display: flex;
  font-size: 1.1rem;
  padding-left: 20px;
  padding-bottom: 20px;
  color: currentColor !important;

  &:last-child {
    padding-bottom: 30px;
  }
`

export const BurgerMenuHeader = styled.div`
  padding-bottom: 20px;
  transition-timing-function: ease;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Chevron = styled(ChevronDown)`
  transition: transform 300ms ease-in-out;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  ${props => props.direction === 'up' && `
    transform: rotate(180deg);
  `}
`

export const Button = styled(BaseButton)`
  margin: 0;
  transition: 150ms ease-in;
`
