import styled, { css } from 'styled-components'
import Color from 'color'
import variables from '../../styles/variables'

const background = variables.colors.sunsetOrange.light
const textColor = variables.colors.sunsetOrange.dark

export const Anchor = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export const Container = styled.div`
  width: 100%;
  color: ${textColor};
  background: ${background};
  transition: background 0.15s ease-in-out;

  & a, & a:visited {
    color: ${textColor};
    text-decoration: underline;
  }

  & a:hover {
    color: ${Color(textColor).lighten(0.05).string()};
  }

  ${props => props.hasUrl && css`
    &:hover {
      background: ${Color(background).lighten(0.05).string()};
      color: ${Color(textColor).lighten(0.05).string()};
    }
  `}
`

export const Banner = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 auto;
  max-width: 1400px;
  padding: 18px 30px 18px 30px;
`
