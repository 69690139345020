import styled from 'styled-components'

export const Input = styled.input`
  display: inline-block;
  width: 100%;
  font-weight: 400;
  border: 0;
  border-radius: 30px;
  padding: 14px 24px;
  max-width: 400px;
  font-size: 1rem;

  ${props => props.inputStyle === 'square' && `
    border-radius: 3px;
  `}
`
