import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  children: PropTypes.node.isRequired,
  topBarBgColor: PropTypes.string,
  contentBgColor: PropTypes.string,
  titleColor: PropTypes.string,
  title: PropTypes.string,
}

const defaultProps = {
  topBarBgColor: '#EDF1F5',
  contentBgColor: '#FFFFFF',
  titleColor: '#333',
  title: '',
}

const BrowserWindow = (props) => {
  const {
    children,
    topBarBgColor,
    contentBgColor,
    title,
    titleColor
  } = props

  return (
    <styles.Container>
      <styles.TopBar backgroundColor={topBarBgColor}>
        <styles.TopBarCirclesContainer>
          <styles.RedCircle />
          <styles.YellowCircle />
          <styles.GreenCircle />
        </styles.TopBarCirclesContainer>

        <styles.TitleContainer>
          <styles.Title color={titleColor}>{title}</styles.Title>
        </styles.TitleContainer>
      </styles.TopBar>

      <styles.Content background={contentBgColor}>
        {children}
      </styles.Content>
    </styles.Container>
  )
}

BrowserWindow.propTypes = propTypes
BrowserWindow.defaultProps = defaultProps

export default BrowserWindow
