import styled from 'styled-components'
import { Copy } from '@styled-icons/fa-regular'
import variables from '../../styles/variables'

export const Container = styled.div`
  position: relative;
`

export const CopyIcon = styled(Copy)`
  width: 20px;
  height: 20px;
  margin-top: 23px;
  color: ${variables.colors.silverTree.medium};
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`

export const CopiedText = styled.span`
  color: ${variables.colors.silverTree.medium};
  font-style: italic;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
`

export const CopyAsCurlText = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${variables.colors.silverTree.dark};
  background-color: ${variables.colors.silverTree.lighter};
  padding: 5px 10px;
  border-radius: 8px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`
