import React from 'react'
import { Markdown } from '..'
import * as styles from './styles'
import { infoCircle, warningTriangle, errorCircle } from '../../styles/icons'

function getIcon(alertType) {
  switch (alertType) {
    case 'warning':
      return warningTriangle
    case 'error':
      return errorCircle
    case 'info':
    default:
      return infoCircle
  }
}

export default (props) => {
  const { type, children } = props

  return (
    <styles.Container {...props}>
      <styles.Icon src={getIcon(type)} />

      <styles.Content>
        <Markdown>
          {children}
        </Markdown>
      </styles.Content>
    </styles.Container>
  )
}
