import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col } from 'react-styled-flexboxgrid'
import { format, utcToZonedTime } from 'date-fns-tz'
import * as styles from './styles'

const propTypes = {
  header: PropTypes.string
}

const defaultProps = {
  header: 'Need help?'
}

class NeedHelp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: format(utcToZonedTime(Date.now(), 'Europe/Helsinki'), 'p')
    }
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    )
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  tick() {
    this.setState({
      time: format(utcToZonedTime(Date.now(), 'Europe/Helsinki'), 'p')
    })
  }

  render() {
    const { props } = this
    const { data } = props
    const { time } = this.state
    return (
      <styles.Container>
        <Row>
          <Col sm={8} xs={12}>
            <styles.HelpText>
              {data.info}
            </styles.HelpText>
          </Col>
          <Col sm={1} xs={0} />
          <Col sm={3} xs={12}>
            <styles.Time>
              {time}
            </styles.Time>
            <styles.Location>
              {data.location}
            </styles.Location>
          </Col>
        </Row>
      </styles.Container>
    )
  }
}

export default function NeedHelpWrapper({ header }) {
  return (
    <StaticQuery
      query={graphql`
        query GetNeedHelp {
          allYaml(filter: {file: {eq: "need-help"}}) {
            edges {
              node {
                info
                location

                needHelpMethods {
                  text
                  url
                  info
                  external
                  target
                }
              }
            }
          }
        }
      `
      }
      render={(data) => {
        const { allYaml } = data
        const { edges } = allYaml
        const { node } = _.first(edges)
        return (
          <NeedHelp data={node} header={header} />
        )
      }}
    />
  )
}

NeedHelpWrapper.propTypes = propTypes
NeedHelpWrapper.defaultProps = defaultProps
