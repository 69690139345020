import React from 'react'
import ReactResponsive from 'react-responsive'

const IS_BROWSER = typeof window !== 'undefined'

const MediaQuery = (props) => {
  const { children, query } = props


  // When building the html files to be served from backend, we want the values to be forced so that
  // the content is rendered. In the browser, we want the width to come from their actual
  // browser. We force the backend content to match desktop layout to render the
  // nav bar visibly for SEO purposes.
  // Normally we would want to render the mobile version first to be aligned
  // with our mobile-first CSS approach, but we estimated that then Google would penalize
  // from main nav not being visible for the users without JS
  const mediaQueryProps = IS_BROWSER
    ? {}
    : { values: { width: '1000em' } }

  // We need to add parentheses to the media query expression in node because the underlying library
  // used is css-mediaquery which requires this. See:
  // https://github.com/ericf/css-mediaquery/issues/17
  const finalQuery = IS_BROWSER
    ? query
    : `(${query})`

  return (
    <ReactResponsive {...props} {...mediaQueryProps} query={finalQuery}>{children}</ReactResponsive>
  )
}

export default MediaQuery
