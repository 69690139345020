import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as styles from './styles'
import { BlogCard } from '..'
import slugifyPathFromTitle from '../../utils/slugifyPathFromTitle'

const propTypes = {
  blogs: PropTypes.array.isRequired,
  paginationOpts: PropTypes.object,
  baseUrl: PropTypes.string.isRequired
}

const defaultProps = {
  paginationOpts: {}
}

export default function BlogList({ blogs, paginationOpts, baseUrl }) {
  const {
    numPages, currentPage, isFirst, isLast, prevPage, nextPage
  } = paginationOpts
  return (
    <React.Fragment>
      <styles.BlogList>
        {_.map(blogs, (blog, index) => {
          const { title, path } = blog.frontmatter
          const slugifiedPath = slugifyPathFromTitle(title, path)
          return (
            <styles.BlogItem key={index}>
              <BlogCard path={slugifiedPath} content={blog} />
            </styles.BlogItem>
          )
        }) }
      </styles.BlogList>
      {!_.isEmpty(paginationOpts) && (
        <div>
          <styles.PageList>
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                <styles.PrevPage />
              </Link>
            )}
            {Array.from({ length: numPages }, (page, i) => {
              const isSelected = i + 1 === currentPage
              return (
                <li key={i}>
                  <styles.PageLink
                    $isSelected={isSelected}
                    to={`${baseUrl}${i === 0 ? '' : i + 1}`}
                  >
                    {i + 1}
                  </styles.PageLink>
                </li>
              )
            })}
            {!isLast && (
              <Link to={nextPage} rel="next">
                <styles.NextPage />
              </Link>
            )}
          </styles.PageList>
        </div>
      )}
    </React.Fragment>
  )
}

BlogList.propTypes = propTypes
BlogList.defaultProps = defaultProps
