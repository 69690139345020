import styled from 'styled-components'
import { Link } from 'gatsby'
import { ChevronRight } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: 300px;
  padding: 60px 0;
  background: ${variables.colors.oracle.dark};
  color: white;
  text-align: center;

  p {
    margin: 0;
    max-width: 100%;
  }

  ul li {
    margin: 0 0 6px 0;
  }

  @media ${variables.media.sm} {
    padding: 100px 0;
    text-align: left;
  }
`

export const FooterList = styled.ul`
  padding: 0;
  list-style: none;
`
export const Wrapper = styled(TextWrapper)`
  width: 100%;

  @media ${variables.media.sm} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ColumnHeader = styled(Heading)`
  ${headerStyles.headerXSmall}
  color: ${variables.colors.success};
`
export const Block = styled.div`
  &:not(:last-child) {
    margin-bottom: 60px;
  }

  @media ${variables.media.sm} {
    width: 20%;
  }
`
export const Address = styled.div`
  margin-bottom: 25px;
`
export const InfoText = styled.div`
  margin: 0 auto 10px auto;
  max-width: 200px;
  @media ${variables.media.sm} {
    margin: 0 0 10px 0;
  }
`
export const FollowText = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`
export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${variables.colors.warning};
  transition: transform 0.15s ease;
  transform: translateX(0);

  :hover, :visited {
    color: ${variables.colors.warning};
  }
  :hover {
    transform: translateX(2px);
  }

  @media ${variables.media.sm} {
    justify-content: flex-start;
  }
`
export const StyledChevronRight = styled(ChevronRight)`
  position: relative;
  right: -2px;
  top: 1px;
  width: 15px;
  height: 15px;
`
export const StyledExternalLink = StyledLink.withComponent('a')
export const SocialMediaLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${variables.media.sm} {
    justify-content: flex-start;
  }
`
