// https://github.com/gatsbyjs/gatsby/issues/7003
import 'babel-polyfill'
import React from 'react'
import { registerLanguage } from 'react-syntax-highlighter/prism-light'
import { registerLanguage as hljsRegisterLanguage } from 'react-syntax-highlighter/light'
import js from 'react-syntax-highlighter/dist/languages/hljs/javascript'
import bash from 'react-syntax-highlighter/dist/languages/hljs/bash'
import javascript from 'react-syntax-highlighter/dist/languages/prism/javascript'
import 'katex/dist/katex.css'
import 'prismjs/themes/prism.css'
import 'rc-slider/assets/index.css'
import './src/styles/burger-menu.css'
import './src/styles/blog-syntax-highlight.css'
import 'react-typist/dist/Typist.css'
import { GlobalLayout } from './src/layouts'

// Set syntax highlighter to have javascript as language option
registerLanguage('javascript', javascript)
hljsRegisterLanguage('javascript', js)
hljsRegisterLanguage('bash', bash)

// Needed so that gatsby correctly imports the file and global.css
export const onClientEntry = () => {}

// We're just passing props as is
// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
  return <GlobalLayout gatsbyWrapProps={props}>{element}</GlobalLayout>
}
