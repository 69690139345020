import styled from 'styled-components'
import Color from 'color'
import variables from '../../styles/variables'

export const Container = styled.div`
  /* rc-slider customizations */

  /* Make the handle slightly larger */
  .rc-slider-handle {
    margin-left: -10px;
    margin-top: -8px;
    width: 20px;
    height: 20px;
  }

  .rc-slider-rail {
    background-color: ${Color(variables.colors.silverTree.dark).alpha(1).string()};
  }
  .rc-slider-track {
    background-color: ${variables.colors.sunsetOrange.lighter};
  }
  .rc-slider-handle {
    border: solid 2px ${variables.colors.sunsetOrange.lighter};
    background-color: ${variables.colors.sunsetOrange.lighter};
  }
  .rc-slider-handle:focus {
    border-color: ${variables.colors.sunsetOrange.lighter};
  }
  .rc-slider-handle-click-focused:focus {
    border-color: ${variables.colors.sunsetOrange.lighter};
  }
  .rc-slider-handle:hover {
    border-color: ${variables.colors.sunsetOrange.lighter};
    box-shadow: 0 0 0 5px ${Color(variables.colors.sunsetOrange.lighter).alpha(0.3).string()};
  }
  .rc-slider-handle:active {
    border-color: ${Color(variables.colors.sunsetOrange.lighter).lighten(0.15).string()};
    box-shadow: 0 0 0 5px ${Color(variables.colors.sunsetOrange.lighter).alpha(0.3).string()};
  }
  .rc-slider-mark-text {
    color: rgba(255, 255, 255, 0.5);
  }
  .rc-slider-mark-text-active {
    color: white;
  }
  .rc-slider-dot {
    border: 2px solid ${variables.colors.silverTree.dark};
    background-color: ${variables.colors.silverTree.dark};
  }
  .rc-slider-dot-active {
    border: 2px solid ${variables.colors.sunsetOrange.lighter};
    background-color: ${variables.colors.sunsetOrange.lighter};
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    background-color: #fff;
  }
`
