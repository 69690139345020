import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as styles from './styles'
import {
  BlogCard
} from '..'
import slugifyPathFromTitle from '../../utils/slugifyPathFromTitle'

const propTypes = {
  header: PropTypes.string.isRequired,
  blog: PropTypes.array.isRequired
}

export default function LatestBlogList({ header, blog }) {
  return (
    <styles.Container>
      <styles.BlockHeader>
        {header}
      </styles.BlockHeader>
      <styles.BlogList>
        {_.map(blog, (entry, index) => {
          const { title, path } = entry.frontmatter
          const slugifiedPath = slugifyPathFromTitle(title, path)
          return (
            <styles.BlogItem key={index}>
              <BlogCard path={slugifiedPath} content={entry} />
            </styles.BlogItem>
          )
        }) }
      </styles.BlogList>
    </styles.Container>
  )
}

LatestBlogList.propTypes = propTypes
