import styled from 'styled-components'
import variables from '../../styles/variables'
import { TextWrapper as BaseTextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const IntroContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${props => `
    background: ${props.background};
  `}
`

export const TextWrapper = styled(BaseTextWrapper)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
`

export const CenteredHeader = styled(Heading)`
  ${headerStyles.headerSmall}
  color: white;
  text-align: center;
  font-weight: 400;
  font-family: ${variables.fonts.body};
  margin-bottom: 1rem;
  animation: slideUp 0.5s ease-in-out;
`

export const CenteredHeaderInfo = styled.p`
  text-align: center;
  max-width: 400px;
  color: ${variables.colors.oracle.light};
  margin-bottom: 38px;
  margin-top: 0;
  animation: slideUp 0.5s ease-in-out 0.1s;
  animation-fill-mode: forwards;  
  opacity: 0;
`

export const IntroSectionSideSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`

export const IntroSectionBottomSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: -75px;
    left: 10%;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`
