import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Parallax } from 'react-scroll-parallax'
import { AnimationWrapper } from '..'
import system10 from '../../../assets/system10.svg'
import MainAnimation from '../../../assets/main-animation.json'
import * as styles from './styles'

const propTypes = {
  content: PropTypes.object.isRequired
}

const Landing = (props) => {
  const { content } = props
  return (
    <styles.IntroContainer>
      <styles.IntroSection>
        <Row>
          <Col xs={12} sm={6}>
            <styles.IntroHeader>
              { content.header }
              <br />
              <span className="nowrap">{ content.headerNoWrapPart }</span>
            </styles.IntroHeader>
            <styles.IntroTagline>
              { content.tagline }
            </styles.IntroTagline>

            <styles.IntroTagline>
              { content.footerTagline }
            </styles.IntroTagline>
            <styles.ButtonContainer>
              <Link to={content.firstButtonUrl}>
                <styles.Button>{content.firstButtonText}</styles.Button>
              </Link>
              <Link to={content.secondButtonUrl}>
                <styles.Button>{content.secondButtonText}</styles.Button>
              </Link>
            </styles.ButtonContainer>
          </Col>

          <Col xs={12} sm={6}>
            <styles.IntroSectionAnimationContainer>
              <styles.AnimationContainer>
                <AnimationWrapper
                  animation={MainAnimation}
                />
              </styles.AnimationContainer>
            </styles.IntroSectionAnimationContainer>
          </Col>
        </Row>
      </styles.IntroSection>

      <Parallax y={['-30px', '30px']}>
        <styles.IntroSectionSideSquare src={system10} alt="" />
      </Parallax>
    </styles.IntroContainer>
  )
}

Landing.propTypes = propTypes

export default Landing
