import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as styles from './styles'
import { Section, IconLink } from '..'
import twitterIcon from '../../../assets/twitter.svg'
import githubIcon from '../../../assets/github.svg'
import facebookIcon from '../../../assets/facebook.svg'
import linkedinIcon from '../../../assets/linkedin.svg'
import mediumIcon from '../../../assets/medium.svg'
import redditIcon from '../../../assets/reddit.svg'
import youtubeIcon from '../../../assets/yt.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

const Footer = ({ data }) => {
  const sortedLinks = _.sortBy(data.column3Links, link => link.text)
  const socialMedias = [
    {
      url: data.column2SocialLinks.twitter,
      iconUrl: twitterIcon
    },
    {
      url: data.column2SocialLinks.github,
      iconUrl: githubIcon
    },
    {
      url: data.column2SocialLinks.facebook,
      iconUrl: facebookIcon
    },
    {
      url: data.column2SocialLinks.linkedin,
      iconUrl: linkedinIcon
    },
    {
      url: data.column2SocialLinks.medium,
      iconUrl: mediumIcon
    },
    {
      url: data.column2SocialLinks.youtube,
      iconUrl: youtubeIcon
    },
    {
      url: data.column2SocialLinks.reddit,
      iconUrl: redditIcon
    }
  ]

  return (
    <Section>
      <styles.Container>
        <styles.Wrapper>
          <styles.Block>
            <styles.ColumnHeader>{data.column1Header}</styles.ColumnHeader>
            <styles.Address>
              <styles.InfoText>
                {_.map(data.column1Rows, (row, i) => {
                  return (
                    <p key={i}>{row}</p>
                  )
                })}
              </styles.InfoText>
            </styles.Address>

          </styles.Block>
          <styles.Block>
            <styles.ColumnHeader>{data.column2Header}</styles.ColumnHeader>
            {_.map(data.column2Links, (link, i) => {
              return (
                link.external
                  ? (
                    <styles.StyledExternalLink
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      <span>{link.text}</span>
                      {link.chevron ? <styles.StyledChevronRight /> : <span />}
                    </styles.StyledExternalLink>
                  )
                  : (
                    <styles.StyledLink to={link.url} key={i}>
                      <span>{link.text}</span>
                      {link.chevron ? <styles.StyledChevronRight /> : <span />}
                    </styles.StyledLink>
                  )
              )
            })}
            <styles.FollowText>
              <p>{data.column2Follow}</p>
            </styles.FollowText>
            <styles.SocialMediaLinks>
              {_.map(socialMedias, (socialMedia, i) => {
                return (
                  <IconLink key={i} url={socialMedia.url} iconUrl={socialMedia.iconUrl} />
                )
              })}
            </styles.SocialMediaLinks>
          </styles.Block>
          <styles.Block>
            <styles.ColumnHeader>{data.column3Header}</styles.ColumnHeader>
            <styles.FooterList>
              {_.map(sortedLinks, (link, i) => {
                return (
                  link.external
                    ? (
                      <li key={i}>
                        <styles.StyledExternalLink
                          target="_blank"
                          rel="noopener noreferrer"
                          href={link.url}
                        >
                          <span>{link.text}</span>
                          {link.chevron ? <styles.StyledChevronRight /> : <span />}
                        </styles.StyledExternalLink>
                      </li>
                    )
                    : (
                      <li key={i}>
                        <styles.StyledLink to={link.url} key={i}>
                          <span>{link.text}</span>
                          {link.chevron ? <styles.StyledChevronRight /> : <span />}
                        </styles.StyledLink>
                      </li>
                    )
                )
              })}
            </styles.FooterList>
          </styles.Block>
        </styles.Wrapper>
      </styles.Container>
    </Section>
  )
}

Footer.propTypes = propTypes

export default () => (
  <StaticQuery
    query={graphql`
      query GetFooter {
        allYaml(filter: {file: {eq: "footer"}}) {
          edges {
            node {
              column1Header
              column1Rows

              column2Header
              column2Links {
                text
                url
                external
                chevron
              }
              column2Follow
              column2SocialLinks {
                twitter
                github
                facebook
                linkedin
                medium
                youtube
                reddit
              }

              column3Header
              column3Links {
                text
                url
                external
                chevron
              }
            }
          }
        }
      }
    `
    }
    render={(data) => {
      const { allYaml } = data
      const { edges } = allYaml
      const { node } = _.first(edges)
      return (
        <Footer data={node} />
      )
    }}
  />
)
