import styled from 'styled-components'
import variables from '../../styles/variables'
import { TextWrapper as BaseTextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 50px 0 !important;
  color: ${variables.colors.secondary};
  background: #F4F4F4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled(BaseTextWrapper)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
`

export const Header = styled(Heading)`
  ${headerStyles.headerSmall};
  color: ${variables.colors.eunry.medium};
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
