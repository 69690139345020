import styled from 'styled-components'
import Color from 'color'
import { Col, Row } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const StepsContainer = styled.div`
  position: relative;
`
export const StepContainer = styled.div`
  @media ${variables.media.sm} {
    border-width: 2px;
  }

  border-left: 2px dashed ${Color(variables.colors.silverTree.dark).alpha(0.5).string()};
  padding-left: 20px;
  margin-left: 10px;

  &:last-of-type {
    border-color: rgba(1, 1, 1, 0);
  }

  @media ${variables.media.sm}{
    margin-left: 0;
  }
`
export const HeaderSpan = styled.span`
  margin-left: 12px;
  display: inline-flex;
`

export const StepHeader = styled(Heading)`
  ${headerStyles.headerXSmall}
  display: inline-flex;
`
export const ChildRow = styled(Row)`
  padding-bottom: 40px;
`

export const ChildCol = styled(Col)`

`

export const StepNumber = styled.span`
  font-size: 0.9rem;
  font-family: ${variables.fonts.body};
  width: 28px;
  height: 28px;
  display: inline-block;
  box-sizing: initial;
  border: 1px solid;
  text-align: center;
  border-radius: 50%;
  margin-left: -36px;
  position: relative;
  top: -2px;
  line-height: 28px;
  box-sizing: content-box;
  background: ${props => props.bgColor};
  flex: 0 0 auto;

  @media ${variables.media.sm} {
    font-size: 1rem;
    font-family: ${variables.fonts.body};
    width: 32px;
    height: 32px;
    border: 2px solid;
    border-radius: 50%;
    margin-left: -39px;
    margin-right: 16px;
    line-height: 33px;
  }
`
