import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  url: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired
}

const IconLink = (props) => {
  const { url, iconUrl } = props
  return (
    <styles.Container>
      <a href={url} target="_blank" rel="noreferrer noopener">
        <styles.Icon src={iconUrl} alt="icon" />
      </a>
    </styles.Container>
  )
}

IconLink.propTypes = propTypes

export default IconLink
