import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import sizeMe from 'react-sizeme'
import { Link } from 'gatsby'
import * as styles from './styles'
import slugifyPathFromTitle from '../../utils/slugifyPathFromTitle'

function isMoveCardsLeftPossible(position, itemsCount, windowSize) {
  return position + itemsCount > windowSize
}

function isMoveCardsRightPossible(position) {
  return position < 0
}

const propTypes = {
  useCases: PropTypes.array.isRequired
}

class MoreUseCasesCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollButtonWidth: 30,
      minCardWidth: 260,
      carouselPosition: 0,
    }
  }

  getPosition = () => {
    const { state } = this
    return state.carouselPosition
  }

  setPosition = (pos) => {
    this.setState({
      carouselPosition: pos,
    })
  }

  getCarouselWidth = () => {
    const { props, state } = this
    return props.size.width - 2 * state.scrollButtonWidth
  }

  getCarouselSize = () => {
    const { minCardWidth } = this.state
    const carouselWidth = this.getCarouselWidth()
    const floorCarouselSize = Math.floor(carouselWidth / minCardWidth)
    const carouselSize = floorCarouselSize !== 0 ? floorCarouselSize : 1
    const cardWidth = carouselWidth / carouselSize
    return { cardWidth, carouselSize }
  }

  isMoveCardsRightPossible = () => {
    return isMoveCardsRightPossible(this.getPosition())
  }

  isMoveCardsLeftPossible = () => {
    const { props } = this
    const { carouselSize } = this.getCarouselSize()
    return isMoveCardsLeftPossible(this.getPosition(), props.useCases.length, carouselSize)
  }

  moveCardsLeft = (_add) => {
    if (!this.isMoveCardsLeftPossible(_add)) {
      return
    }

    this.setPosition(this.getPosition() - 1)
  }

  moveCardsRight = () => {
    if (!this.isMoveCardsRightPossible()) {
      return
    }

    this.setPosition(this.getPosition() + 1)
  }

  render() {
    const {
      scrollButtonWidth
    } = this.state
    const { useCases } = this.props
    const cardsContainerWidth = this.getCarouselWidth()
    const { cardWidth } = this.getCarouselSize()
    const moveNRight = this.getPosition()
    const carouselTransform = `translate(${cardWidth * moveNRight}px)`
    const disabledUseCaseCard = (useCase) => {
      return (
        <styles.UseCaseCard
          disabled={useCase.disabled}
        >
          <styles.UseCaseCardTitle>
            <p>Coming soon:</p>
            {useCase.title}
          </styles.UseCaseCardTitle>
        </styles.UseCaseCard>
      )
    }

    const useCaseCard = (useCase) => {
      const slugifiedPath = slugifyPathFromTitle(useCase.title, useCase.path)
      return (
        <Link to={slugifiedPath}>
          <styles.UseCaseCard
            disabled={useCase.disabled}
          >
            <styles.UseCaseCardTitle>
              {useCase.title}
            </styles.UseCaseCardTitle>
          </styles.UseCaseCard>
        </Link>
      )
    }
    return (
      <styles.Container>
        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsRight}
          movePossible={this.isMoveCardsRightPossible()}
        >
          <styles.ChevronLeftIcon />
        </styles.ScrollButton>

        <styles.UseCaseCardsContainer width={cardsContainerWidth}>
          <styles.UseCaseCards transform={carouselTransform}>
            {_.map(useCases, (useCase, index) => {
              return (
                <styles.CardListItem itemIndex={index} disable={useCase.disabled} key={index}>
                  <styles.CardContainer width={cardWidth}>
                    {useCase.disabled ? disabledUseCaseCard(useCase) : useCaseCard(useCase)}
                  </styles.CardContainer>
                </styles.CardListItem>
              )
            })}
          </styles.UseCaseCards>
        </styles.UseCaseCardsContainer>

        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsLeft}
          movePossible={this.isMoveCardsLeftPossible()}
        >
          <styles.ChevronRightIcon />
        </styles.ScrollButton>

      </styles.Container>
    )
  }
}

MoreUseCasesCarousel.propTypes = propTypes

export default sizeMe({ refreshRate: 40 })(MoreUseCasesCarousel)
