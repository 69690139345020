import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  z-index: 10;
`
export const ComponentContainer = styled.div`
  height: 100%;
`
export const ChildrenContainer = styled.div`
  pointer-events: none;
  padding: 0px 70px 70px 70px;
  opacity: 0;
  transform: translate(-50%, 110%) scale(0);
  transform-origin: 50% -15%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: opacity 0.15s ease, transform 0.25s ease;

  ${props => props.open && `
    pointer-events: auto;
    opacity: 1;
    transform: translate(-50%, 100%) scale(1);
  `}
`

export const MenuContainer = styled.div`
  position: relative;
  padding: 30px 15px 35px 15px;
  background: ${props => props.backgroundColor || '#fff'};
  border-radius: 3px;
  box-sizing: border-box;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    border-bottom: 10px solid ${props => props.backgroundColor || '#fff'};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: 50%;
    width: 0px;
    height: 0px;
    transform: translate(-50%, 0);
  }
`
