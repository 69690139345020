import styled from 'styled-components'
import Color from 'color'
import posed from 'react-pose'
import { ChevronRight, ChevronLeft } from '@styled-icons/octicons'
import variables from '../../styles/variables'

const cardBorderRadius = '8px'

export const Container = styled.div`
  max-width: 1200px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`

export const UseCaseCardsContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  width: ${props => props.width}px;
`

export const UseCaseCards = styled.ul`
  display: flex;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${props => props.transform};
`

export const CardListItem = styled.li`
  transition: top 0.3s ease;
  position: relative;
  top: 0;
  ${props => !props.open && `
    &:hover {
      top: -5px;
    }
  `}
`

export const ScrollButton = styled.a`
  color: #8C8C8C;
  width: ${props => props.width}px;
  text-align: center;
  ${props => props.movePossible && `

    color: ${variables.colors.jade.medium};
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: ${Color(variables.colors.jade.medium).darken(0.3).string()};
    }
  `}
`

export const CardContainer = styled.div`
  width: ${props => props.width}px;
  height: 125px;
`

const Frame = posed.div({
  enter: {
    applyAtStart: { display: 'block' },
    opacity: 1,
  },
  exit: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
  }
})

export const BackgroundFrame = styled(Frame)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  transform: translateZ(0);
  background: rgba(0, 0, 0, 0.8);
`
const CardTitle = posed.div({
  init: {
    y: 0,
    opacity: 1,
  },
  hover: {
    y: 0,
    opacity: 1,
    transition: { duration: 200 },
  }
})

export const UseCaseCardTitle = styled(CardTitle)`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UseCaseCard = styled.div`
  display: inline-block;
  width: 230px;
  height: 125px;
  border-radius: ${cardBorderRadius};
  background: ${variables.colors.jade.medium};
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  transition: box-shadow 0.3s ease;
  font-weight: bold;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.1);

  ${props => !props.open && `
    &:hover {
      cursor: pointer;
      box-shadow: 0 8px 10px 3px rgba(0, 0, 0, 0.12);
    }
  `}
}
`

const CardOverlay = posed.div({
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: { duration: 200 }
  }
})

export const UseCaseCardOverlay = styled(CardOverlay)`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  span {
    padding: 6px 24px;
    background: rgba(0, 65, 33, 0.8);
    border-radius: 20px;
  }
}
`
export const ChevronRightIcon = styled(ChevronRight)`
  width: 25px;
  height: 25px;
`
export const ChevronLeftIcon = styled(ChevronLeft)`
  width: 25px;
  height: 25px;
`
