import styled from 'styled-components'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'
import plusPattern from '../../../assets/plus-pattern.svg'
import { ReactComponent as RealTimeLearning } from '../../../assets/winner.svg'
import { ReactComponent as PredictAnyVariable } from '../../../assets/real-time-learning-black.svg'
import { ReactComponent as HiddenMagic } from '../../../assets/api-black.svg'

export const Container = styled.div`
`

export const PlusPatternContainer = styled.div`
  width: 100%;
  background-image: url(${plusPattern});
`

export const LogoContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
`

export const SectionContainer = styled(TextWrapper)`
  padding: 20px 0 0 0;
  @media ${variables.media.sm} {
    padding: 60px 40px;
  }
  @media ${variables.media.md} {
    padding: 0 30px;
  }
`

export const QuoteContainer = styled(SectionContainer)`
  @media ${variables.media.md} {
    padding: 60px 30px;
  }
`

export const SectionHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  color: ${variables.colors.oracle.dark};
  margin-left: 20px;
  margin-bottom: 0;

  @media ${variables.media.sm} {
    margin-left: 0;
  }
`

export const Centerer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.color === 'green' && `
    background: rgba(0, 184, 86, 0.22);
  `}

  ${props => props.color === 'pink' && `
    background: rgba(255, 169, 211, 0.38);
  `}

  ${props => props.color === 'blue' && `
    background: rgba(66, 192, 247, 0.28);
  `}
`

export const RealTimeLearningIcon = styled(RealTimeLearning)`
  width: 42px;
  height: 80px;
`

export const PredictAnyVariableIcon = styled(PredictAnyVariable)`
  width: 80px;
  height: 80px;
`

export const HiddenMagicIcon = styled(HiddenMagic)`
  width: 80px;
  height: 80px;
`

export const IntegrationPlatfromContainer = styled.div`
  width: 100vw;
  margin: 0 auto;
  background: ${variables.colors.oracle.medium};
  display: flex;
  justify-content: center;
`
