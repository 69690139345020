import styled from 'styled-components'
import variables from '../../styles/variables'
import { ReactComponent as LikeIcon } from '../../../assets/like-icon.svg'
import { ReactComponent as DislikeIcon } from '../../../assets/dislike-icon.svg'
import { ReactComponent as LoadingGreen } from '../../../assets/loading-green.svg'


export const Container = styled.div`
  width: 100%;
  
  @media ${variables.media.md} {
    display: flex;
    justify-content: space-between;
  }
`
export const SubContainer = styled.div`
  @media ${variables.media.md} {
    flex: 1 50%;
    padding: 0 20px;
    border-right: ${props => (props.border ? '1px solid #f1f1f1' : 'none')}
  }
`
export const InputContainer = styled.div`
  height: 38px;
  display: flex;
  background: #FFFFFF;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #D6D6D6;
  border-radius: 6px;
  padding: 0.5rem 1rem;
`
export const Input = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  font-size: 0.8em;
  color: ${variables.colors.oracle.dark};

  @media only percy {
    visibility: hidden;
  }
`
export const KickstarterHeader = styled.p`
  font-weight: 600;
`

export const ParameterInputContainer = styled.div`
  margin-bottom: 1rem;
  @media ${variables.media.md} {
    margin-bottom: 1.7rem;
  }
`

export const ParameterLabel = styled.label`
  font-style: italic;
  display: block;
  margin-bottom: 0.5rem;
  @media ${variables.media.md} {
    margin-bottom: 0.9rem;
  }
`

export const PredictResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  animation: fadeIn 2s ease-in-out;
  
  @media ${variables.media.md} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const SuccessProbabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const SuccessProbability = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid ${variables.colors.oracle.light};
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media ${variables.media.md} {
    width: 120px;
    height: 120px;
  }
`
export const PercentageText = styled.span`
  font-size: ${props => `${props.fontSizeSm}rem`};
  font-family: ${variables.fonts.header};
  color: ${variables.colors.silverTree.dark};
  
  @media ${variables.media.md} {
    font-size: ${props => `${props.fontSizeMd}rem`};
  }
`

export const SuccessProbabilityText = styled.p`
  font-size: 0.7rem;
  font-weight: 600;
   @media ${variables.media.md} {
    font-size: 0.9rem;
  }
`
export const ParametersContainer = styled.div`
  margin-right: 30px;
  @media ${variables.media.md} {
    margin: 30px 0 0 0;
  }
`
export const Parameter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 400;
`
export const ParameterText = styled.div`
  text-align: right;
  margin-right: 15px;
  font-size: 0.8rem;
  flex: 1 50%;
  @media ${variables.media.md} {
    font-size: 1rem;
  }
`
export const AitoLikeIcon = styled(LikeIcon)`
  width: 20px;
`

export const AitoDislikeIcon = styled(DislikeIcon)`
  width: 20px;
`
export const LoadingIcon = styled(LoadingGreen)`
  animation: fadeout 2s ease-in-out;
  width: 40px;
  height: 130px;
`
