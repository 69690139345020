import styled from 'styled-components'
import posed from 'react-pose'
import { Col } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import { TextWrapper } from '../../styles/base'

export const Container = styled.div`
  width: 100%;
  color: ${variables.colors.oracle.dark};
  margin: 0;
  padding: 0;
`

export const SubContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
  padding: 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${variables.media.sm} {
    padding: 40px;
    display: block;
  }

  @media ${variables.media.md} {
    padding: 40px 120px 20px 120px;
    display: block;
  }
`

export const BlockHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`

export const ItemContainer = styled.div`
  padding: 10px 20px;
  margin-bottom: 20px;
  transition : border 500ms ease-out;
  ${props => (props.isOpen ? `
    border-left: 2px solid ${variables.colors.jade.medium};
    color: ${variables.colors.oracle.darker};
  ` : `
    border-left: 2px solid #C4C4C4;
    color: #888888;
    &:hover {
      color: #666666;
    }
  `)}
`

export const ItemHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  font-family: ${variables.fonts.body};
  cursor: pointer;
  margin-bottom: 0;
  transition : color 500ms ease-out;
`

export const ItemText = styled.p`
`

export const ImageCol = styled(Col)`
  margin-bottom: 50px;
  @media ${variables.media.sm} {
    margin-bottom: 0;
  }
`

export const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
`

const Image = posed.img({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})

export const DisplayImage = styled(Image)`
  width: ${props => props.imageWidth};
  height: ${props => props.imageHeight};
  margin-bottom: 30px;
`
