import React from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import * as styles from './styles'

const propTypes = {
  categoryInput: PropTypes.object,
  goalInput: PropTypes.object,
  durationInput: PropTypes.object,
  content: PropTypes.object.isRequired,
  paramsTypingDone: PropTypes.bool,
  onFrontEndAnimationDone: PropTypes.func.isRequired
}

const defaultProps = {
  categoryInput: null,
  goalInput: null,
  durationInput: null,
  paramsTypingDone: false
}

const KickstarterFrontEnd = (props) => {
  const {
    content, categoryInput, goalInput, durationInput, paramsTypingDone, onFrontEndAnimationDone
  } = props

  const predictionResult = (
    <styles.PredictResultContainer>
      <styles.SuccessProbabilityContainer>
        <styles.SuccessProbability>
          <styles.PercentageText fontSizeSm={1.5} fontSizeMd={2.5}>
            <CountUp end={87} onEnd={onFrontEndAnimationDone} />
            <styles.PercentageText fontSizeSm={1} fontSizeMd={1.7}>%</styles.PercentageText>
          </styles.PercentageText>
        </styles.SuccessProbability>
        <styles.SuccessProbabilityText>
          {content.probabilityText}
        </styles.SuccessProbabilityText>
      </styles.SuccessProbabilityContainer>
      <styles.ParametersContainer>
        <styles.Parameter>
          <styles.ParameterText>{content.label1}</styles.ParameterText>
          <styles.AitoLikeIcon />
        </styles.Parameter>
        <styles.Parameter>
          <styles.ParameterText>{content.label2}</styles.ParameterText>
          <styles.AitoLikeIcon />
        </styles.Parameter>
        <styles.Parameter>
          <styles.ParameterText>{content.label3}</styles.ParameterText>
          <styles.AitoDislikeIcon />
        </styles.Parameter>
      </styles.ParametersContainer>
    </styles.PredictResultContainer>
  )

  return (
    <styles.Container>
      <styles.SubContainer border>
        <styles.KickstarterHeader>{content.header1}</styles.KickstarterHeader>
        <styles.ParameterInputContainer>
          <styles.ParameterLabel htmlFor="category">{content.label1}</styles.ParameterLabel>
          <styles.InputContainer>
            {categoryInput}
          </styles.InputContainer>
        </styles.ParameterInputContainer>

        <styles.ParameterInputContainer>
          <styles.ParameterLabel htmlFor="duration">{`${content.label2} (days)`}</styles.ParameterLabel>
          <styles.InputContainer>
            {durationInput}
          </styles.InputContainer>
        </styles.ParameterInputContainer>

        <styles.ParameterInputContainer>
          <styles.ParameterLabel htmlFor="goal">{content.label3}</styles.ParameterLabel>
          <styles.InputContainer>
            {goalInput}
          </styles.InputContainer>
        </styles.ParameterInputContainer>
      </styles.SubContainer>

      <styles.SubContainer>
        <styles.KickstarterHeader>{content.header2}</styles.KickstarterHeader>
        {paramsTypingDone ? predictionResult : <styles.LoadingIcon />}
      </styles.SubContainer>
    </styles.Container>
  )
}

KickstarterFrontEnd.propTypes = propTypes
KickstarterFrontEnd.defaultProps = defaultProps

export default KickstarterFrontEnd
