import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

const defaultProps = {
  onFocus: () => null,
  onBlur: () => null
}

const HoverMenuItem = (props) => {
  const {
    to,
    children,
    onFocus,
    onBlur,
    external
  } = props

  return (
    <styles.Container {...props} onFocus={onFocus} onBlur={onBlur}>
      <styles.CustomLink to={to} external={external}>
        {children}
      </styles.CustomLink>
    </styles.Container>
  )
}

HoverMenuItem.propTypes = propTypes
HoverMenuItem.defaultProps = defaultProps
export default HoverMenuItem
