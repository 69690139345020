import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  color: ${variables.colors.silverTree.darker};
  width: 100%;
  margin: 0;
  padding: 0;
`

export const GetStartedContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background: ${variables.colors.silverTree.dark};
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${variables.media.md} {
    padding: 100px 120px;
    text-align: left;
    display: block;
  }
`

export const DataToResultsContainer = styled.div`
  padding: 60px 20px;

  background: ${variables.colors.sunsetOrange.lighter};
  color: ${variables.colors.silverTree.dark};
  position: relative;

  @media ${variables.media.sm} {
    padding: 100px 120px;
  }
`

export const GetStartedHeader = styled(Heading)`
  color: white;
  margin-bottom: 60px;
`

export const CardRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CardCol = styled(Col)`
  display: flex !important;
  justify-content: center;

  @media ${variables.media.md} {
    display: block !important;
  }
`

export const CardContainer = styled.div`
  width: 280px;
  min-height: 350px;
  padding: 20px 20px 0 20px;
  background: ${variables.colors.sunsetOrange.lighter};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 5px;

  @media ${variables.media.md} {
    width: auto;
    min-width: 240px;
    max-width: 280px;
  }
`

export const CardHeader = styled(Heading)`
  ${headerStyles.headerXXSmall}
  color: ${variables.colors.silverTree.dark};
  font-family: ${variables.fonts.body};
  margin-bottom: 1.5rem;
`

export const CardImageContainer = styled.div`
  background: ${variables.colors.carnationPink.light};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardImage = styled.img`
  &.book {
    height: 40px;
  }
  &.code {
    height: 28px;
  }
  &.blocks {
    height: 35px;
  }
`
export const CardText = styled.div`
  padding: 24px;
  text-align: center;
  max-width: 200px;
`
export const GetStartedText = styled.p`
  color: white;
  margin-top: 35px;

  & a {
    text-decoration: underline;
    color: currentColor !important;
    display: inline-block;
  }
`
