import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'
import _ from 'lodash'
import * as styles from './styles'
import { Section, Markdown } from '..'

const propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  icons: PropTypes.array,
  border: PropTypes.bool,
  borderColor: PropTypes.string
}

const defaultProps = {
  border: false,
  borderColor: 'black',
  icons: [],
}

const ThreeColumnsList = (props) => {
  const {
    header, items, border, borderColor, icons
  } = props
  return (
    <styles.Container>
      <styles.SubContainer>
        <Row>
          <Col xs={12}>
            <styles.BlockHeader>
              {header}
            </styles.BlockHeader>
          </Col>
        </Row>
        <Section>
          <Row>
            {_.map(items, (item, i) => {
              return (
                <styles.ItemCol sm={4} xs={12} key={i}>
                  <styles.ItemContainer border={border} borderColor={borderColor}>
                    {icons ? icons[i] : undefined}
                    <styles.ItemHeader>
                      {item.header}
                    </styles.ItemHeader>
                    <styles.ItemText>
                      <Markdown>
                        {item.text}
                      </Markdown>
                    </styles.ItemText>
                  </styles.ItemContainer>
                </styles.ItemCol>
              )
            })}
          </Row>
        </Section>
      </styles.SubContainer>
    </styles.Container>
  )
}

ThreeColumnsList.propTypes = propTypes
ThreeColumnsList.defaultProps = defaultProps

export default ThreeColumnsList
