import React from 'react'
import { PoseGroup } from 'react-pose'
import * as styles from './styles'
import closeIcon from '../../../assets/icons/close.svg'
import { CodeSnippet } from '..'

const LEFT_ARROW_KEY = 37
const RIGHT_ARROW_KEY = 39

class QueryModal extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyUp, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyUp, false)
  }

  onKeyUp = (event) => {
    const { moveModal, item } = this.props
    if (event.keyCode === LEFT_ARROW_KEY) {
      moveModal(item.id, 'right')
    } else if (event.keyCode === RIGHT_ARROW_KEY) {
      moveModal(item.id, 'left')
    }
  }

  render() {
    const {
      item, closeCard, moveModal, items, movingDirection
    } = this.props
    const {
      id, title, description, query, endpoint, response, responseExplanation
    } = item
    const leftButtonDisable = id === items[0].id
    const rightButtonDisable = id === items[items.length - 1].id
    return (
      <styles.UseCaseModal {...this.props} direction={movingDirection}>
        <styles.ScrollButton
          disable={leftButtonDisable}
          onClick={() => moveModal(id, 'right')}
          left
        >
          <styles.ChevronLeftIcon />
        </styles.ScrollButton>

        <styles.UseCaseCardContent>
          <styles.UseCaseCardContentTopHalf>
            <div>
              <PoseGroup>
                <styles.UseCaseCardTitle key={id} direction={movingDirection}>
                  {title}
                </styles.UseCaseCardTitle>
              </PoseGroup>
              <styles.UseCaseCardTextContainer>
                <PoseGroup>
                  <styles.UseCaseCardDescription key={id} direction={movingDirection}>
                    {description}
                  </styles.UseCaseCardDescription>
                </PoseGroup>
              </styles.UseCaseCardTextContainer>
            </div>
            <styles.CloseUseCardLink onClick={closeCard}>
              <img src={closeIcon} alt="close" />
            </styles.CloseUseCardLink>
          </styles.UseCaseCardContentTopHalf>

          <styles.UseCaseCardContentBottomHalf>
            <styles.UseCaseCardTextContainer>
              <PoseGroup>
                <styles.UseCaseCardLabel key={id} direction={movingDirection}>
                  Query:
                </styles.UseCaseCardLabel>
              </PoseGroup>
            </styles.UseCaseCardTextContainer>
            <PoseGroup>
              <styles.UseCaseCardQuery key={id} direction={movingDirection}>
                <CodeSnippet copiable copyAsCurl curlEndpoint={endpoint} key={id} language="javascript">
                  {query}
                </CodeSnippet>
              </styles.UseCaseCardQuery>
            </PoseGroup>

            <styles.UseCaseCardTextContainer>
              <PoseGroup>
                <styles.UseCaseCardLabel key={id} direction={movingDirection}>
                  Response explanation:
                </styles.UseCaseCardLabel>
              </PoseGroup>
            </styles.UseCaseCardTextContainer>

            <styles.UseCaseCardTextContainer>
              <PoseGroup>
                <styles.UseCaseCardText key={id} direction={movingDirection}>
                  {responseExplanation}
                </styles.UseCaseCardText>
              </PoseGroup>
            </styles.UseCaseCardTextContainer>

            <styles.UseCaseCardTextContainer>
              <PoseGroup>
                <styles.UseCaseCardLabel key={id} direction={movingDirection}>
                  Response:
                </styles.UseCaseCardLabel>
              </PoseGroup>
            </styles.UseCaseCardTextContainer>

            <PoseGroup>
              <styles.UseCaseCardQuery key={id} direction={movingDirection}>
                <CodeSnippet key={id} language="javascript">
                  {response}
                </CodeSnippet>
              </styles.UseCaseCardQuery>
            </PoseGroup>
          </styles.UseCaseCardContentBottomHalf>
        </styles.UseCaseCardContent>

        <styles.ScrollButton disable={rightButtonDisable} onClick={() => moveModal(id, 'left')}>
          <styles.ChevronRightIcon />
        </styles.ScrollButton>
      </styles.UseCaseModal>
    )
  }
}

export default QueryModal
