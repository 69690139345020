import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'
import _ from 'lodash'
import * as styles from './styles'
import { AnimationWrapper, Section } from '..'

const propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  icons: PropTypes.array.isRequired
}

export default class RpaApplications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pause: true,
    }
  }

  toggleAnimation = () => {
    this.setState((prevState) => {
      return {
        pause: !prevState.pause
      }
    })
  }

  render() {
    const { pause } = this.state
    const { header, items, icons } = this.props
    return (
      <styles.Container>
        <styles.SubContainer>
          <Row>
            <Col xs={12}>
              <styles.BlockHeader>
                {header}
              </styles.BlockHeader>
            </Col>
          </Row>
          <Section>
            <Row>
              {_.map(items, (item, i) => {
                return (
                  <styles.ItemCol sm={4} xs={12} key={i}>
                    <styles.StyledLink to="/showcases">
                      <styles.ItemContainer
                        onMouseEnter={this.toggleAnimation}
                        onMouseLeave={this.toggleAnimation}
                      >
                        <div>
                          <styles.AnimationContainer>
                            <AnimationWrapper
                              animation={icons[i]}
                              hoverPause={pause}
                              hoverOnly
                            />
                          </styles.AnimationContainer>
                          <styles.ItemHeader>
                            {item.header}
                          </styles.ItemHeader>
                          <styles.ItemText>
                            {item.text}
                          </styles.ItemText>
                        </div>
                        <styles.LearnMore>
                          <div className="learn-more">
                            Learn more
                            <styles.Chevron />
                          </div>
                        </styles.LearnMore>
                      </styles.ItemContainer>
                    </styles.StyledLink>
                  </styles.ItemCol>
                )
              })}
            </Row>
          </Section>
        </styles.SubContainer>
      </styles.Container>
    )
  }
}

RpaApplications.propTypes = propTypes
