import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'
import { Markdown } from '../index'
import variables from '../../styles/variables'

const propTypes = {
  data: PropTypes.object.isRequired,
  color: PropTypes.string,
  bubbleColor: PropTypes.string,
}

const defaultProps = {
  color: variables.colors.oracle.dark,
  bubbleColor: variables.colors.carnationPink.lighter
}

const QuoteCard = ({ data, color, bubbleColor }) => {
  return (
    <styles.Container>
      <styles.QuoteBox color={color} background={bubbleColor}>
        {data.text}
      </styles.QuoteBox>
      <styles.QuoteAuthorWrapper>
        <styles.QuoteAuthor>
          <styles.Avatar fluid={data.authorImg.childImageSharp.fluid} />
          <styles.AuthorInfo color={color}>
            <p>{data.authorName}</p>
            <Markdown>
              {data.authorPosition}
            </Markdown>
          </styles.AuthorInfo>
        </styles.QuoteAuthor>
      </styles.QuoteAuthorWrapper>
    </styles.Container>
  )
}

QuoteCard.propTypes = propTypes
QuoteCard.defaultProps = defaultProps

export default QuoteCard
