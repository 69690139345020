import styled from 'styled-components'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  padding: 70px 0;
  background: ${variables.colors.sunsetOrange.lighter};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const Header = styled(Heading)`
  ${headerStyles.headerMedium}
  color: ${variables.colors.silverTree.dark};
`
