import React from 'react'
import PropTypes from 'prop-types'

const sectionProps = {
  children: PropTypes.node.isRequired
}

const Level = React.createContext(1)

export function Section(props) {
  return (
    <Level.Consumer>
      {level => <Level.Provider value={level + 1}>{props.children}</Level.Provider>}
    </Level.Consumer>
  )
}

export function Heading(props) {
  return (
    <Level.Consumer>
      {(level) => {
        const HeadingLevel = `h${Math.min(level, 6)}`
        return <HeadingLevel {...props} />
      }}
    </Level.Consumer>
  )
}

Section.propTypes = sectionProps
