import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Col, Row } from 'react-styled-flexboxgrid'
import Collapse from '@kunukn/react-collapse'
import {
  Section, Markdown, Button, QuoteCard, MediaQuery
} from '..'
import * as styles from './styles'
import variables from '../../styles/variables'

const propTypes = {
  color: PropTypes.string,
  useCase: PropTypes.object.isRequired
}

const defaultProps = {
  color: variables.colors.jade.medium
}

export default class PopularUseCaseCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openUseCase: false
    }
  }

  componentDidMount() {
    const { useCase, useCaseIdParam } = this.props
    const { id } = useCase
    if (useCaseIdParam === id) this.setState({ openUseCase: true })
  }

  expandUseCaseCard = () => {
    this.setState({ openUseCase: true })
  }

  collapseUseCaseCard = () => {
    this.setState({ openUseCase: false })
  }

  toggleUseCaseCard = (id) => {
    const { openUseCase } = this.state
    this.setState({ openUseCase: !openUseCase })
    if (!openUseCase) {
      window.analytics.track('Showcase expanded', {
        showcase: id
      })
    }
  }

  renderCollapsibleContent = () => {
    const { useCase } = this.props
    const {
      content, tags, blogPostUrl, tutorialUrl, statistics, quote
    } = useCase

    return (
      <Row>
        <Col md={6} sm={6} xs={12}>
          <styles.TextContainer>
            <Markdown>{content}</Markdown>
          </styles.TextContainer>
          {_.map(tags, (tag, i) => <styles.Tag key={i}>{`#${tag}`}</styles.Tag>)}
          <styles.ButtonContainer>
            {blogPostUrl
              && (
                <Link to={blogPostUrl}>
                  <Button size="small">Blog post</Button>
                </Link>
              )
            }
            {tutorialUrl
              && (
                <a href={tutorialUrl} target="_blank" rel="noopener noreferrer">
                  <Button size="small">Hands on tutorial</Button>
                </a>
              )
            }
          </styles.ButtonContainer>
        </Col>
        <Section>
          <Col md={6} sm={6} xs={12}>
            <styles.StatisticsContainer>
              {_.map(statistics, (s, i) => {
                return (
                  statistics.length !== 1
                    ? (
                      <styles.Statistic key={i}>
                        <styles.BigText>{s.bigText}</styles.BigText>
                        <styles.SmallText>{s.smallText}</styles.SmallText>
                      </styles.Statistic>
                    ) : (
                      <styles.SingleStatistic key={i}>
                        <styles.BigText>{s.bigText}</styles.BigText>
                        <styles.SmallText marginLeft>{s.smallText}</styles.SmallText>
                      </styles.SingleStatistic>
                    )
                )
              })}
            </styles.StatisticsContainer>
            <styles.QuoteContainer>
              {quote && <QuoteCard data={quote} />}
            </styles.QuoteContainer>
          </Col>
        </Section>
      </Row>
    )
  }

  render() {
    const { useCase, color } = this.props
    const {
      id, title, summary, imageUrl
    } = useCase
    const { openUseCase } = this.state
    return (
      <styles.Container id={id}>
        <styles.Card color={color} onClick={() => this.toggleUseCaseCard(id)}>
          <MediaQuery query={variables.media.sm}>
            {(matches) => {
              if (matches) {
                return (
                  <Row>
                    <Col md={6} sm={6} xs={12}>
                      <styles.CardHeader>{title}</styles.CardHeader>
                      <styles.Introduction>
                        <Markdown>
                          {summary}
                        </Markdown>
                      </styles.Introduction>
                    </Col>
                    <Section>
                      <Col md={6} sm={6} xs={12}>
                        <styles.UseCaseImgContainer>
                          <styles.UseCaseImg src={imageUrl} alt="customer logo" />
                        </styles.UseCaseImgContainer>
                      </Col>
                    </Section>
                  </Row>
                )
              }

              return (
                <Row>
                  <Section>
                    <Col md={6} sm={6} xs={12}>
                      <styles.UseCaseImgContainer>
                        <styles.UseCaseImg src={imageUrl} alt="customer logo" />
                      </styles.UseCaseImgContainer>
                    </Col>
                  </Section>
                  <Col md={6} sm={6} xs={12}>
                    <styles.CardHeader>{title}</styles.CardHeader>
                    <styles.Introduction>
                      <Markdown>
                        {summary}
                      </Markdown>
                    </styles.Introduction>
                  </Col>
                </Row>
              )
            }}
          </MediaQuery>

          <Collapse
            isOpen={openUseCase}
            render={this.renderCollapsibleContent}
          />
          {openUseCase
            ? (
              <styles.LinkRow onClick={this.collapseUseCaseCard}>
                Read less
                <styles.ChevronUpIcon />
              </styles.LinkRow>
            ) : (
              <styles.LinkRow onClick={this.expandUseCaseCard}>
                Read more
                <styles.ChevronDownIcon />
              </styles.LinkRow>
            )
          }
        </styles.Card>
      </styles.Container>
    )
  }
}

PopularUseCaseCard.propTypes = propTypes
PopularUseCaseCard.defaultProps = defaultProps
