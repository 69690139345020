import { createGlobalStyle } from 'styled-components'
import variables from './variables'

const {
  h1Scale,
  h2Scale,
  h3Scale,
  h4Scale,
  h5Scale,
  h6Scale,
  smallScreenHeaderScaleFactor,
  headerMarginTopFactor,
  headerMarginBottomFactor
} = variables.headerScale

// We move the header styles to an exportable variable here so that we can use this styles
// for the headers in pages or component that need to have the right semantics meaning but also need
// to keep the visual style (eg: an h1 header for semantics meaning but it need the visual style of
// an h4 header). The mobile scale has also been moved here to assure the correct visual when we use
// this style in other stylesheets.

export const headerStyles = {
  headerXXSmall: `
    word-spacing: normal;
    font-size: ${h6Scale}rem;
    margin-top: ${h6Scale * headerMarginTopFactor}rem;
    margin-bottom: ${h6Scale * headerMarginBottomFactor}rem;
  `,
  headerXSmall: `
    word-spacing: normal;
    font-size: ${h5Scale}rem;
    margin-top: ${h5Scale * headerMarginTopFactor}rem;
    margin-bottom: ${h5Scale * headerMarginBottomFactor}rem;
  `,
  headerSmall: `
    word-spacing: normal;
    font-size: ${h4Scale * smallScreenHeaderScaleFactor}rem;
    margin-top: ${h4Scale * headerMarginTopFactor * smallScreenHeaderScaleFactor}rem;
    margin-bottom: ${h4Scale * headerMarginBottomFactor * smallScreenHeaderScaleFactor}rem;

    @media ${variables.media.sm} {
      font-size: ${h4Scale}rem;
      margin-top: ${h4Scale * headerMarginTopFactor}rem;
      margin-bottom: ${h4Scale * headerMarginBottomFactor}rem;
    }
  `,
  headerMedium: `
    word-spacing: normal;
    font-size: ${h3Scale * smallScreenHeaderScaleFactor}rem;
    margin-top: ${h3Scale * headerMarginTopFactor * smallScreenHeaderScaleFactor}rem;
    margin-bottom: ${h3Scale * headerMarginBottomFactor * smallScreenHeaderScaleFactor}rem;

    @media ${variables.media.sm} {
      font-size: ${h3Scale}rem;
      margin-top: ${h3Scale * headerMarginTopFactor}rem;
      margin-bottom: ${h3Scale * headerMarginBottomFactor}rem;
    }
  `,
  headerLarge: `
    word-spacing: -0.2rem;
    font-size: ${h2Scale * smallScreenHeaderScaleFactor}rem;
    margin-top: ${h2Scale * headerMarginTopFactor * smallScreenHeaderScaleFactor}rem;
    margin-bottom: ${h2Scale * headerMarginBottomFactor * smallScreenHeaderScaleFactor}rem;

    @media ${variables.media.sm} {
      word-spacing: -0.5rem;
      font-size: ${h2Scale}rem;
      margin-top: ${h2Scale * headerMarginTopFactor}rem;
      margin-bottom: ${h2Scale * headerMarginBottomFactor}rem;
    }
  `,
  headerXLarge: `
    word-spacing: -0.2rem;
    font-size: ${h1Scale * smallScreenHeaderScaleFactor}rem;
    margin-top: ${h1Scale * headerMarginTopFactor * smallScreenHeaderScaleFactor}rem;
    margin-bottom: ${h1Scale * headerMarginBottomFactor * smallScreenHeaderScaleFactor}rem;

    @media ${variables.media.sm} {
      word-spacing: -0.5rem;
      font-size: ${h1Scale}rem;
      margin-top: ${h1Scale * headerMarginTopFactor}rem;
      margin-bottom: ${h1Scale * headerMarginBottomFactor}rem;
    }
  `
}

export const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #___gatsby > div {
    width: 100%;
  }

  html, body {
    background: ${variables.colors.oracle.dark};
  }

  /* Remove paddings and margins from container elements for predictable layouting */
  html, body, div, section {
    margin: 0;
    padding: 0;
  }

  /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  a, a:visited {
    color: ${variables.colors.jade.medium};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  ::selection {
    background: #FFAD9D;
    color: white;
  }

  .nowrap {
    white-space: nowrap;
  }

  input, textarea {
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 24px;
  }

  /*
   * * * * * * *
   * Typography
   * * * * * * *
  */

  html {
    font-family: ${variables.fonts.body};
  }

  /* Remove default margins from font elements as we define our own below */
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-weight: 700;
    font-family: ${variables.fonts.header};
    font-variant-ligatures: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  /*
  By default, add a sensible default to limit the maximum width of text.
  This makes text easier to read.
  */
  p {
    max-width: 40rem;
    margin: 1rem 0;
    line-height: 1.4;
  }

  /* Set good default margins for headers and paragraphs

                                     <- no extra margin wanted here, because it's the first header
    Header h1


    Lorem ipsum dolor sit
    amet, no vocibus invenire
    per, vis detraxit repudiare
    et.

    Lorem ipsum dolor sit
    amet, no vocibus invenire
    per, vis detraxit repudiare
    et.
                                      <- but here we want to have a a bit larger margin between
                                         paragraph and h2. That's why we set margin-top to 0
    Header h2
  */

  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
  }

  h1 {
    ${headerStyles.headerXLarge}
  }

  h2 {
    ${headerStyles.headerLarge}
  }

  h3 {
    ${headerStyles.headerMedium}
  }

  h4 {
    ${headerStyles.headerSmall}
  }

  h5 {
    ${headerStyles.headerXSmall}
  }

  h6 {
    ${headerStyles.headerXXSmall}
  }

  hr {
    margin: 2rem 0;
  }

  /* tooltip styles need to be in global because they are placed under body in DOM */
  /* We add more CSS specifity with "body" selector */
  body .rc-slider-tooltip {
    z-index: 100000000;
  }

  body .rc-slider-tooltip-inner {
    background-color: ${variables.colors.sunsetOrange.lighter};
    border-radius: 1px;
    padding: 6px 14px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
    color: ${variables.colors.silverTree.darker};
    font-weight: 700;
    font-family: ${variables.fonts.body};
  }

  body .rc-slider-tooltip-arrow {
    border-top-color: ${variables.colors.sunsetOrange.lighter} !important;
  }
  
  .collapse-css-transition {
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  /* keyframe animation */

  @keyframes smallSlideUp {
    0% {
      transform: translateY(15px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media only percy {
    animation-name: none !important;
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot {
    background: transparent;
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    width: 10px;
    height: 10px;
    background: ${variables.colors.silverTree.light};
  }
`
