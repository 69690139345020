import styled from 'styled-components'
import Color from 'color'
import variables from '../../styles/variables'

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.6rem 2.3rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 6px;
  transition: background-color 0.15s ease-in-out, transform 0.2s ease-in-out, border 0.15s ease-in-out, transform 0.2s ease-in-out;
  background: ${props => props.buttonColor};
  color: ${props => props.textColor};
  font-weight: bold;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 2px solid ${props => props.buttonColor};

  ${props => props.disabled && `
    background: #cccccc;
    color: #666666;
    border: none;
  `}

  ${props => props.size === 'small' && `
    font-size: 0.8rem;
    padding: 0.5rem 2rem;
  `}

  ${props => props.size === 'large' && `
    padding: 0.7rem 2.6rem;
    font-size: 1rem;
  `}

  ${props => props.type === 'plain' && `
    box-shadow: 0px 2px 8px 0px ${Color(variables.colors.jade.dark).alpha(0.1).string()};
  `}

  ${props => props.type === 'outline' && `
    background: none;
    border: 2px solid ${props.buttonColor};
  `}

  &:hover {
    background: ${props => Color(props.buttonColor).lighten(0.05).string()};
    border: 2px solid ${props => Color(props.buttonColor).lighten(0.05).string()};
    ${props => props.disabled && `
      background: #cccccc;
      color: #666666;
      border: none;
    `}
    ${props => props.type === 'outline' && `
      background: ${props.buttonColor};
      color: ${props.hoverTextColor}};
    `}
  }
`
