import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import sizeMe from 'react-sizeme'
import * as styles from './styles'

import futuriceLogo from '../../../assets/customer-logos/futurice-logo.svg'
import futuriceLogoGray from '../../../assets/customer-logos/futurice-logo-gray.svg'

import sisuaLogo from '../../../assets/customer-logos/sisua-logo.svg'
import sisuaLogoGray from '../../../assets/customer-logos/sisua-logo-gray.svg'

import quandriLogo from '../../../assets/customer-logos/quandri-logo.svg'
import quandriLogoGray from '../../../assets/customer-logos/quandri-logo-gray.svg'

import sofigateLogo from '../../../assets/customer-logos/sofigate-logo.svg'
import sofigateLogoGray from '../../../assets/customer-logos/sofigate-logo-gray.svg'

import midpointedLogo from '../../../assets/customer-logos/midpointed-logo.svg'
import midpointedLogoGray from '../../../assets/customer-logos/midpointed-logo-gray.svg'

import crLogo from '../../../assets/customer-logos/columbia-road-logo.svg'
import crLogoGray from '../../../assets/customer-logos/columbia-road-logo-gray.svg'

import qfactoryLogo from '../../../assets/customer-logos/qfactory-logo.svg'
import qfactoryLogoGray from '../../../assets/customer-logos/qfactory-logo-gray.svg'

import calLogo from '../../../assets/customer-logos/cal-logo.svg'
import calLogoGray from '../../../assets/customer-logos/cal-logo-gray.svg'

const items = [
  {
    id: 0,
    src: qfactoryLogo,
    graySrc: qfactoryLogoGray,
    url: 'https://q-factory.fi/en/',
    isExternal: true
  },
  {
    id: 1,
    src: futuriceLogo,
    graySrc: futuriceLogoGray,
    url: 'https://futurice.com/',
    isExternal: true
  },
  {
    id: 2,
    src: sisuaLogo,
    graySrc: sisuaLogoGray,
    url: 'https://www.sisuadigital.com/',
    isExternal: true
  },
  {
    id: 3,
    src: sofigateLogo,
    graySrc: sofigateLogoGray,
    url: 'https://www.sofigate.com/',
    isExternal: true
  },
  {
    id: 4,
    src: calLogo,
    graySrc: calLogoGray,
    url: 'https://cognitiveautomationlabs.com',
    isExternal: true
  },
  {
    id: 5,
    src: midpointedLogo,
    graySrc: midpointedLogoGray,
    url: 'https://www.midpointed.fi/',
    isExternal: true
  },
  {
    id: 6,
    src: quandriLogo,
    graySrc: quandriLogoGray,
    url: 'https://www.quandri.io/',
    isExternal: true
  },
  {
    id: 7,
    src: crLogo,
    graySrc: crLogoGray,
    url: 'https://www.columbiaroad.com/',
    isExternal: true
  }
]

function isMoveCardsLeftPossible(position, itemsCount, windowSize) {
  return position + itemsCount > windowSize
}

function isMoveCardsRightPossible(position) {
  return position < 0
}

class PartnerCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollButtonWidth: 30,
      minCardWidth: 250,
      carouselPosition: 0,
    }
  }

  getPosition = () => {
    const { state } = this
    return state.carouselPosition
  }

  setPosition = (pos) => {
    this.setState({
      carouselPosition: pos,
    })
  }

  getCarouselWidth = () => {
    const { props, state } = this
    return props.size.width - 2 * state.scrollButtonWidth
  }

  getCarouselSize = () => {
    const { minCardWidth } = this.state
    const carouselWidth = this.getCarouselWidth()
    const floorCarouselSize = Math.floor(carouselWidth / minCardWidth)
    const carouselSize = floorCarouselSize !== 0 ? floorCarouselSize : 1
    const cardWidth = carouselWidth / carouselSize
    return { cardWidth, carouselSize }
  }

  isMoveCardsRightPossible = () => {
    return isMoveCardsRightPossible(this.getPosition())
  }

  isMoveCardsLeftPossible = () => {
    const { carouselSize } = this.getCarouselSize()
    return isMoveCardsLeftPossible(this.getPosition(), items.length, carouselSize)
  }

  moveCardsLeft = (_add) => {
    if (!this.isMoveCardsLeftPossible(_add)) {
      return
    }

    this.setPosition(this.getPosition() - 1)
  }

  moveCardsRight = () => {
    if (!this.isMoveCardsRightPossible()) {
      return
    }

    this.setPosition(this.getPosition() + 1)
  }

  onLogoMouseEnter = (index) => {
    this.setState({
      logoHover: index,
    })
  }

  onLogoMouseLeave = (index) => {
    const { state } = this
    if (state.logoHover === index) {
      this.setState({
        logoHover: null,
      })
    }
  }

  render() {
    const {
      scrollButtonWidth,
      logoHover
    } = this.state
    const cardsContainerWidth = this.getCarouselWidth()
    const { cardWidth } = this.getCarouselSize()
    const moveNRight = this.getPosition()
    const carouselTransform = `translate(${cardWidth * moveNRight}px)`

    return (
      <styles.Container>
        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsRight}
          movePossible={this.isMoveCardsRightPossible()}
        >
          <styles.ChevronLeftIcon />
        </styles.ScrollButton>

        <styles.CarouselItemsContainer width={cardsContainerWidth}>
          <styles.CarouselItems transform={carouselTransform}>
            {_.map(items, (item, index) => {
              const isLogoHovered = logoHover === index

              const onHoverCallbackProps = {
                onMouseEnter: () => this.onLogoMouseEnter(index),
                onMouseLeave: () => this.onLogoMouseLeave(index),
              }
              return (
                <styles.LogoListItem itemIndex={index} key={index}>
                  <styles.LogoContainer width={cardWidth}>
                    {item.isExternal
                      ? (
                        <a href={item.url}>
                          <styles.LogoBox {...onHoverCallbackProps}>
                            <styles.Logo
                              src={isLogoHovered ? item.src : item.graySrc}
                              alt="customer logo"
                            />
                          </styles.LogoBox>
                        </a>
                      ) : (
                        <Link to={item.url}>
                          <styles.LogoBox {...onHoverCallbackProps}>
                            <styles.Logo
                              src={isLogoHovered ? item.src : item.graySrc}
                              alt="customer logo"
                            />
                          </styles.LogoBox>
                        </Link>
                      )
                    }
                  </styles.LogoContainer>
                </styles.LogoListItem>
              )
            })}
          </styles.CarouselItems>
        </styles.CarouselItemsContainer>

        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsLeft}
          movePossible={this.isMoveCardsLeftPossible()}
        >
          <styles.ChevronRightIcon />
        </styles.ScrollButton>

      </styles.Container>
    )
  }
}

export default sizeMe({ refreshRate: 40 })(PartnerCarousel)
