import React from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  SimpleCallToActionBanner,
  LogoCarousel,
  AitoShowcases,
  QuotesBlock,
  ThreeColumnsList,
  LatestBlogList,
  AlternatingStories
} from '..'
import * as styles from './styles'

const propTypes = {
  data: PropTypes.object.isRequired,
  latestBlog: PropTypes.array.isRequired
}

export default function IndexContent({ data, latestBlog }) {
  const aitoFeaturesIcons = [
    (
      <styles.IconWrapper color="green">
        <styles.RealTimeLearningIcon />
      </styles.IconWrapper>
    ),
    (
      <styles.IconWrapper color="pink">
        <styles.PredictAnyVariableIcon />
      </styles.IconWrapper>
    ),
    (
      <styles.IconWrapper color="blue">
        <styles.HiddenMagicIcon />
      </styles.IconWrapper>
    )
  ]

  return (
    <Section>
      <styles.Container>

        <styles.LogoContainer>
          <styles.SectionContainer>
            <LogoCarousel />
          </styles.SectionContainer>
        </styles.LogoContainer>

        <styles.PlusPatternContainer>
          <styles.SectionContainer>
            <ThreeColumnsList
              header={data.aitoFeaturesHeader}
              items={data.aitoFeatures}
              icons={aitoFeaturesIcons}
            />
          </styles.SectionContainer>
        </styles.PlusPatternContainer>

        <styles.QuoteContainer>
          <QuotesBlock data={data} />
        </styles.QuoteContainer>

        <styles.IntegrationPlatfromContainer>
          <AlternatingStories data={data} />
        </styles.IntegrationPlatfromContainer>

        <styles.PlusPatternContainer>
          <styles.SectionContainer>
            <AitoShowcases showcases={data.showcases} header={data.showcasesHeader} />
          </styles.SectionContainer>
        </styles.PlusPatternContainer>

        <styles.SectionContainer>
          <LatestBlogList header={data.latestBlogHeader} blog={latestBlog} />
        </styles.SectionContainer>

        <SimpleCallToActionBanner />
      </styles.Container>
    </Section>
  )
}

IndexContent.propTypes = propTypes
