import React from 'react'
import PropTypes from 'prop-types'
import system10 from '../../../assets/system10.svg'
import * as styles from './styles'
import variables from '../../styles/variables'

const propTypes = {
  header: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  background: PropTypes.string,
}

const defaultProps = {
  background: variables.colors.oracle.dark
}

const PageHeader = (props) => {
  const { header, info, background } = props
  return (
    <styles.IntroContainer background={background}>
      <styles.TextWrapper>
        <styles.CenteredHeader>
          {header}
        </styles.CenteredHeader>
        <styles.CenteredHeaderInfo>
          {info}
        </styles.CenteredHeaderInfo>
      </styles.TextWrapper>

      <styles.IntroSectionSideSquare src={system10} alt="" />
      <styles.IntroSectionBottomSquare src={system10} alt="" />
    </styles.IntroContainer>
  )
}

PageHeader.propTypes = propTypes
PageHeader.defaultProps = defaultProps

export default PageHeader
