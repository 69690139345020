import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as styles from './styles'
import { NavBar } from '..'

const propTypes = {
  blogTitle: PropTypes.string.isRequired,
  coverImgLink: PropTypes.string.isRequired,
  blogTags: PropTypes.array.isRequired,
  coverImgCreditLink: PropTypes.string,
  coverImgCreditText: PropTypes.string
}

const defaultProps = {
  coverImgCreditLink: null,
  coverImgCreditText: null
}

const BlogPageHeader = (props) => {
  const {
    blogTitle, coverImgLink, blogTags, coverImgCreditLink, coverImgCreditText
  } = props

  return (
    <styles.Container backgroundImg={coverImgLink}>
      <NavBar background="transparent" textColor="white" />
      <styles.TitleContainer>
        <styles.TextWrapper>
          <styles.CenteredHeader>
            {blogTitle}
          </styles.CenteredHeader>
          <styles.TagRow>
            {_.map(blogTags, (tag, i) => {
              const kebabTag = _.kebabCase(tag)
              return (
                <Link to={`/blog/tags/${kebabTag}/`} key={i}>
                  <styles.Tag>{`#${tag}`}</styles.Tag>
                </Link>
              )
            })}
          </styles.TagRow>
        </styles.TextWrapper>
        {(coverImgCreditLink && coverImgCreditText)
          && (
            <styles.ImgCredit>
              Photo by
              <a href={coverImgCreditLink} target="_blank" rel="noopener noreferrer">
                {` ${coverImgCreditText}`}
              </a>
            </styles.ImgCredit>
          )
        }
      </styles.TitleContainer>
    </styles.Container>
  )
}

BlogPageHeader.propTypes = propTypes
BlogPageHeader.defaultProps = defaultProps

export default BlogPageHeader
