import styled from 'styled-components'
import variables from '../../styles/variables'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'

export const Container = styled.div`
  width: 100%;
  color: ${variables.colors.oracle.dark};
  padding: 20px;

  @media ${variables.media.sm} {
    padding: 60px 40px;
  }

  @media ${variables.media.md} {
    padding: 100px 120px;
  }
`
export const BlockHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
    margin-left: 20px;
  }
`
export const BlogList = styled.ul`
  max-width: 1200px;
  margin: 70px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
export const BlogItem = styled.li`
  width: 100%;
  padding-bottom: 50px;
  @media ${variables.media.sm} {
    flex: 0 0 50%;
    padding: 10px 20px 50px 20px;
  }
  @media ${variables.media.lg} {
    flex: 0 0 33.3333%;
  }
`
