import styled from 'styled-components'
import posed from 'react-pose'
import Color from 'color'
import { ChevronRight, ChevronLeft } from '@styled-icons/octicons'
import variables from '../../styles/variables'

const cardBorderRadius = '8px'

const Button = posed.a({
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 0 }
  },
  exit: {
    y: 0,
    opacity: 0,
    transition: { duration: 0 }
  }
})

export const ScrollButton = styled(Button)`
  color: #8C8C8C;
  text-align: center;
  width: 40px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  left: ${props => (props.left ? 15 : -15)}px;
  z-index: 10;
  ${props => !props.disable && `
    color: ${variables.colors.jade.medium};
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: ${Color(variables.colors.jade.medium).darken(0.3).string()};
    }
  `}
  @media ${variables.media.sm} {
    width: 100px;
    background: none;
    border-radius: none;
    box-shadow: none;
    left: 0;
  }
`

export const ChevronRightIcon = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  margin: 7px 0;
  @media ${variables.media.sm} {
    width: 50px;
    height: 50px;
  }
`
export const ChevronLeftIcon = styled(ChevronLeft)`
  width: 20px;
  height: 20px;
  margin: 7px 0;
  @media ${variables.media.sm} {
    width: 50px;
    height: 50px;
  }
`

const transition = {
  duration: 750,
  ease: [0.08, 0.69, 0.2, 0.99]
}

const Modal = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    delay: 150,
    transition
  },
  exit: {
    x: ({ direction }) => (direction === 'right' ? -100 : 100),
    opacity: 0,
    transition
  }
})

export const UseCaseModal = styled(Modal)`
  width: 100%;
  height: 80%;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media ${variables.media.sm} {
    margin: auto;
    width: 100%;
    max-width: 650px;
    height: 80%;
    max-height: 600px;
  }
`

const CardContent = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 0 }
  },
  exit: {
    y: 0,
    opacity: 0,
    transition: { duration: 0 }
  }
})

export const UseCaseCardContent = styled(CardContent)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${cardBorderRadius};
  overflow: hidden;
`

export const UseCaseCardContentTopHalf = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  width: 100%;
  background: ${variables.colors.jade.medium};
  color: white;
  
  h5 {
    margin: 0 !important;
    padding: 0;
    color: white !important;
  }
  
  @media ${variables.media.sm} {
    height: 23%;
  }
`

export const UseCaseCardContentBottomHalf = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow: auto;
  padding: 25px 20px;
  background: #fafafa;
  color: black;
  font-size: 1rem;
  @media ${variables.media.sm} {
    padding: 24px 40px;
  }
`

export const CloseUseCardLink = styled.a`
  padding: 30px;
  margin-right: -40px;
  cursor: pointer;
  transition: transform 0.1s ease;

  &:hover {
    transform: scale(1.2);
  }
`

const textTransition = {
  duration: 600,
  ease: [0.08, 0.69, 0.2, 0.99]
}

const textPose = {
  enter: {
    x: 0,
    opacity: 1,
    transition: textTransition
  },
  exit: {
    x: ({ direction }) => (direction === 'right' ? -150 : 150),
    opacity: 0,
    transition: {
      duration: 0
    }
  }
}

export const CardTitle = posed.h5(textPose)

export const CardText = posed.span(textPose)

export const UseCaseCardQuery = posed.div(textPose)

export const UseCaseCardTitle = styled(CardTitle)`
  font-size: 16.5px;
  margin: 0;
  padding: 0;
  font-weight: bold !important;
  @media ${variables.media.sm} {
    font-size: 1.35rem;
  }
`

export const UseCaseCardDescription = styled(CardText)`
  font-size: 0.95rem;
  margin-top: 10px;
`

export const UseCaseCardTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const UseCaseCardText = styled(CardText)`
  margin: 13px 10px;
  font-size: 1rem;
  color: ${variables.colors.oracle.dark};
`

export const UseCaseCardLabel = styled(CardText)`
  margin: 0;
  font-weight: bold;
  font-size: 0.85rem;
  background: ${variables.colors.pearlBrush.dark};
  color: white;
  padding: 4px 10px;
  border-radius: 15px;
`
