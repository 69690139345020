import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import { ChevronRight } from '@styled-icons/octicons'
import { Link } from 'gatsby'
import variables from '../../styles/variables'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import { TextWrapper } from '../../styles/base'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`

export const SubContainer = styled(TextWrapper)`
  position: relative;
  color: ${variables.colors.oracle.dark};
  padding: 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${variables.media.sm} {
    padding: 40px;
    display: block;
  }

  @media ${variables.media.md} {
    padding: 40px 120px 20px 120px;
    display: block;
  }
`

export const BlockHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`
export const ItemHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  color: ${variables.colors.oracle.darker};
  font-family: ${variables.fonts.body};
`
export const ItemText = styled.p`
  color: ${variables.colors.oracle.darker};
`
export const ItemCol = styled(Col)`
  padding-bottom: 30px;
  @media ${variables.media.sm} {
    padding-bottom: 0;
  }
`

export const ItemContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${variables.media.md} {
    margin-right: 40px;
  }

  .learn-more {
    font-weight: bold;
    animation: smallSlideUp 0.5s ease-in-out;
    display: none;
  }

  &:hover {
    .learn-more {
      display: block;
    }
  }
`

export const LearnMore = styled.div`
  height: 20px;
`

export const AnimationContainer = styled.div`
  height: 70px;
  width: 70px;
  flex-shrink: 0;
`

export const StyledLink = styled(Link)`
  &:hover{
    text-decoration: none;
  }
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`
