import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import plusPattern from '../../../assets/plus-pattern.svg'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const SectionContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
`
export const SectionRow = styled(Row)`
  width: 100%;
`
export const SectionCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.reverse === true ? 'flex-end' : 'flex-start')};
`
export const WorkflowContainer = styled.div`
  width: 100%;
  background-image: url(${plusPattern});
  margin-top: 10px;
  @media ${variables.media.md} {
    margin-top: 0;
  }
`
export const StepRow = styled(SectionRow)`
  margin-bottom: 70px;
  @media ${variables.media.sm} {
    margin-bottom: 0;
  }
`
export const ButtonContainerDesktop = styled.div`
  visibility: hidden;
  display: none;

  @media ${variables.media.sm} {
    margin-top: 20px;
    visibility: visible;
    display: block;
  }
`
export const ButtonContainerMobile = styled(SectionRow)`
  visibility: visible;
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media ${variables.media.sm} {
    visibility: hidden;
    display: none;
  }
`
export const StyledLink = styled.a`
  margin: 0 auto;
  @media ${variables.media.sm} {
    margin: 0;
  }
`
export const SectionSmallHeader = styled(Heading)`
  ${headerStyles.headerSmall}
  margin-bottom: 20px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`
export const StepSmallHeader = styled(Heading)`
  ${headerStyles.headerXSmall}
  font-family: ${variables.fonts.body};
  font-size: 1rem;
  color: ${variables.colors.silverTree.medium};
  font-weight: 600;
  margin: 0;
`
export const StepBigHeader = styled(Heading)`
  ${headerStyles.headerXSmall}
  font-family: ${variables.fonts.body};
  color: ${variables.colors.oracle.dark};
  font-weight: 600;
  margin: 0;
`
export const StepText = styled.div`
  @media ${variables.media.md} {
    max-width: 400px;
  }
`
export const DataSourceImage = styled.img`
  width: 300px;
  margin: 20px auto 0 auto;
  @media ${variables.media.sm} {
    margin: 0;
  }
`
export const DashLineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const DashLine = styled.img`
  display: none;
  @media ${variables.media.sm} {
    display: block;
    max-width: 400px;
  }
`
export const DashLineReverse = styled(DashLine)`
  transform: scaleX(-1);
`
export const WorkflowButton = styled.div`
  margin-top: 30px;
`
