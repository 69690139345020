const slugify = require('@sindresorhus/slugify')
const compileTemplate = require('./compileTemplate')

function slugifyPathFromTitle(title, path, additionalContext = {}) {
  const opts = {
    customReplacements: [
      ['\'', ''],
    ],
    decamelize: false
  }
  const slug = slugify(title, opts)
  const template = compileTemplate(path)
  return template({
    ...additionalContext, slug
  })
}

module.exports = slugifyPathFromTitle
