import styled from 'styled-components'
import { Link as BaseLink } from 'gatsby'
import { LinkExternal } from '@styled-icons/octicons'

export const Link = styled(BaseLink)`
  color: currentColor !important;
`

export const ExternalLink = styled.a`
  color: currentColor !important;
`

export const ExternalLinkIcon = styled(LinkExternal)`
  height: 0.8rem;
  margin-left: 7px;
  position: relative;
  top: -1px;
  color: currentColor;
`
