import styled from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'

export const Quotes = styled.div`
  display: flex;
  justify-content: center;
  @media ${variables.media.md} {
    padding: 0 180px;
  }
`

export const QuotesRow = styled(Row)`
  width: 100%;
`
