import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import { NavBar, Footer, TopBanner } from '../../components'
import variables from '../../styles/variables'
import * as styles from './styles'
import favicon from '../../../assets/aito-favicon-128.png'
import config from '../../config'

// By default, never show the slideout navbar. Each page should
// use the firstChild prop to show the navbar at a correct position
const DEFAULT_SHOW_HEADER_AT_Y = Infinity

const propTypes = {
  children: PropTypes.node.isRequired,
  firstChild: PropTypes.node,
  displayTopBanner: PropTypes.bool,
  displayNavbar: PropTypes.bool,
  displayFooter: PropTypes.bool,
  description: PropTypes.string,
  sharingImageLink: PropTypes.string,
  sharingTitle: PropTypes.string,
  topBannerProps: PropTypes.object,
  navbarProps: PropTypes.object,
}

const defaultProps = {
  firstChild: null,
  displayTopBanner: false,
  displayNavbar: true,
  displayFooter: true,
  description: '',
  sharingImageLink: 'https://s3.eu-central-1.amazonaws.com/aitoai-public/website-assets/aito-social-image.png',
  sharingTitle: 'Aito.ai',
  navbarProps: {},
  topBannerProps: {},
}

class PageLayout extends React.Component {
  constructor(props) {
    super(props)
    // Set an initial scroll position to compare with the position when
    // scrolling to detect scroll direction
    if (typeof window !== 'undefined') {
      this.PREVIOUS_SCROLL_POSITION = window.pageYOffset
    }
    this.firstChildContainerRef = React.createRef()
    this.state = {
      showSlideOut: false,
      firstChildContainerHeight: Infinity,
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false)

    this.setState({
      firstChildContainerHeight: this.firstChildContainerRef.current.clientHeight
    })
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    const { props, state } = this
    const showHeaderAtY = props.firstChild
      ? state.firstChildContainerHeight + variables.navBarHeight
      : DEFAULT_SHOW_HEADER_AT_Y
    const CURRENT_SCROLL_POSITION = window.pageYOffset

    if (window.scrollY >= showHeaderAtY) {
      // the comparision only happens when users scroll past the first child element
      if (this.PREVIOUS_SCROLL_POSITION > CURRENT_SCROLL_POSITION && !state.showSlideOut) {
        // scroll up => show the nav bar
        this.setState({ showSlideOut: true })
      } else if (this.PREVIOUS_SCROLL_POSITION <= CURRENT_SCROLL_POSITION && state.showSlideOut) {
        // scroll down => hide the nav bar
        this.setState({ showSlideOut: false })
      }
    } else if (window.scrollY < showHeaderAtY && state.showSlideOut) {
      this.setState({ showSlideOut: false })
    }
    this.PREVIOUS_SCROLL_POSITION = CURRENT_SCROLL_POSITION
  }

  render() {
    const { state } = this
    const {
      children,
      firstChild,
      displayTopBanner,
      displayNavbar,
      displayFooter,
      description,
      sharingImageLink,
      sharingTitle,
      topBannerProps,
      navbarProps,
    } = this.props

    return (
      <styles.Container>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="icon" href={favicon} type="image/png" />

          {/* Twitter Card data */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={sharingTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={sharingImageLink} />

          {/* Open Graph data */}
          <meta property="og:title" content={sharingTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://aito.ai/" />
          <meta property="og:image" content={sharingImageLink} />
          <meta property="og:description" content={description} />

          {/* GA verification */}
          <meta name="google-site-verification" content="3htXrCXwKfCC8VxBdDxetHss_cb5CzvA4annUIwGdEw" />
          <meta name="description" content={description} />

          {config.GATSBY_TARGET_ENV !== 'production' && <meta name="robots" content="noindex" />}

        </Helmet>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          style={{ background: variables.colors.oracle.darker }}
          buttonStyle={{ background: variables.colors.jade.medium, color: 'white' }}
        >
          This website uses cookies to enhance the user experience.
          By browsing the website, you consent to the use of cookies.&nbsp;
          <a href="/privacy-policy#how-do-we-use-cookies">Privacy policy</a>
        </CookieConsent>
        {
          displayTopBanner
            ? <TopBanner {...topBannerProps} />
            : null
        }
        {
          displayNavbar
            ? <NavBar {...navbarProps} />
            : null
        }

        {
          displayNavbar
            ? <NavBar {...navbarProps} slideOut showSlideOut={state.showSlideOut} />
            : null
        }

        <styles.Content>
          <div ref={this.firstChildContainerRef}>
            {firstChild}
          </div>

          {children}
        </styles.Content>
        { displayFooter ? <Footer /> : null }
      </styles.Container>
    )
  }
}

PageLayout.propTypes = propTypes
PageLayout.defaultProps = defaultProps

export default PageLayout
