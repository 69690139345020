import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter/prism-light'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as styles from './styles'
import config from '../../config'

const propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  copiable: PropTypes.bool,
  copyAsCurl: PropTypes.bool,
  curlEndpoint: PropTypes.string
}

const defaultProps = {
  copiable: false,
  copyAsCurl: false,
  curlEndpoint: ''
}

class CodeSnippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false
    }
  }

  renderCopyButton = () => {
    const { copyAsCurl, curlEndpoint, children } = this.props
    const { copied } = this.state
    const queryAsCurl = `
    curl -X POST \\
    https://public-1.${config.AITO_APP_HOST_NAME}/${curlEndpoint} \\
    -H 'content-type: application/json' \\
    -H 'x-api-key: bvss2i2dIkaWUfBCdzEO89LpxUkwO3A24hYg8MBq' \\
    -d '\n${children}'
    `

    return (
      <CopyToClipboard
        text={copyAsCurl ? queryAsCurl : children}
        onCopy={() => this.setState({ copied: true })}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          copied
            ? <styles.CopiedText>Copied!</styles.CopiedText>
            : copyAsCurl
              ? <styles.CopyAsCurlText>Copy as cURL</styles.CopyAsCurlText>
              : <styles.CopyIcon>Copy</styles.CopyIcon>
        }
      </CopyToClipboard>
    )
  }

  render() {
    const { children, language, copiable } = this.props
    return (
      <styles.Container>
        <SyntaxHighlighter language={language} useInlineStyles={false}>
          {children}
        </SyntaxHighlighter>
        {copiable
          && this.renderCopyButton()
        }
      </styles.Container>
    )
  }
}

CodeSnippet.propTypes = propTypes
CodeSnippet.defaultProps = defaultProps

export default CodeSnippet
