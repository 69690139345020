import styled from 'styled-components'
import Color from 'color'
import { ChevronRight, ChevronLeft } from '@styled-icons/octicons'
import variables from '../../styles/variables'

const cardBorderRadius = '8px'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media ${variables.media.sm} {
    margin-bottom: 30px;
  }
`

export const CarouselItemsContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  width: ${props => props.width}px;
`

export const CarouselItems = styled.ul`
  display: flex;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${props => props.transform};
`

export const LogoListItem = styled.li`
  transition: top 0.3s ease;
  position: relative;
  top: 0;
  ${props => !props.disable && `
    &:hover {
      top: -5px;
    }
  `}
`

export const ScrollButton = styled.a`
  color: #8C8C8C;
  width: ${props => props.width}px;
  text-align: center;
  ${props => props.movePossible && `

    color: ${variables.colors.jade.medium};
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: ${Color(variables.colors.jade.medium).darken(0.3).string()};
    }
  `}
`

export const LogoContainer = styled.div`
  width: ${props => props.width}px;
  height: 125px;
`

export const LogoBox = styled.div`
  width: 230px;
  height: 125px;
  border-radius: ${cardBorderRadius};
  position: absolute;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
`

export const Logo = styled.img`
  margin: 0 auto;
  height: 100%;
  width: 130px;
  user-select: none;
`


export const ChevronRightIcon = styled(ChevronRight)`
  width: 25px;
  height: 25px;
`
export const ChevronLeftIcon = styled(ChevronLeft)`
  width: 25px;
  height: 25px;
`
