import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import _ from 'lodash'
import { Lottie } from '@crello/react-lottie'
import * as styles from './styles'

/*
Animation Wrapper props
  animation: lottie animation to be animated
  animationSpeed:
  hoverOnly: If animation is wanted to be animated on only hover
  pauseAt: pause animation at time
    example 1.5: animation will run 1.5 times and pause the animation there
*/
const propTypes = {
  // animation is given as a prop to wrapper and it is
  // hard to know the exact shape of the object
  // eslint-disable-next-line
  animation: PropTypes.object,
  path: PropTypes.string,
  hoverOnly: PropTypes.bool,
  animationSpeed: PropTypes.number,
  pauseAt: PropTypes.number,
}

const defaultProps = {
  path: undefined,
  animationSpeed: 1,
  hoverOnly: false,
  pauseAt: 0,
}

const defaultLottieOptions = {
  loop: true,
  isClickToPauseDisabled: true,
  renderer: 'svg',
}

export default class AnimationWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.animationOptions = _.extend({}, defaultLottieOptions, {
      animationData: props.animation,
      path: props.path
    })

    this.state = {
      paused: !props.hoverOnly,
      animationOptions: this.animationOptions,
      clicks: 0,
      loop: 0,
    }

    this.toggleAnimation = this.toggleAnimation.bind(this)
    this.loopComplete = this.loopComplete.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    const { pauseAt } = this.props
    const { paused } = this.state
    if (pauseAt > 0 && paused) {
      this.setState((prevState) => {
        return {
          clicks: prevState.clicks + 1,
          paused: false
        }
      })
    }
  }

  loopComplete(event) {
    this.setState({ loop: event.currentLoop })
  }

  /*
    Pauses the animation at time pauseAt
    If animation is clicked when paused the animation will
    add to pauseAt 1 and the animation will run one cycle
    and then stop to same point defined in pauseAt
  */
  pauseAnimationAtTime(event) {
    const { pauseAt } = this.props
    const { loop, clicks } = this.state

    const pauseTimeDefined = pauseAt > 0
    const timeElapsed = (event.currentTime + loop * event.totalTime)
    const maxAnimationTime = event.totalTime * (pauseAt + clicks)
    const isAnimationTimeElapsed = timeElapsed > maxAnimationTime

    if (pauseTimeDefined && isAnimationTimeElapsed) {
      this.setState({ paused: true })
    }
  }

  toggleAnimation(isVisible) {
    const { hoverOnly } = this.props
    if (!isVisible || hoverOnly) {
      this.setState({ paused: true })
    } else {
      this.setState({ paused: false })
    }
  }

  render() {
    const { paused, animationOptions } = this.state
    const { animationSpeed } = this.props
    return (
      <styles.Container
        onClick={this.onClick}
      >
        <VisibilitySensor
          onClick={this.onClick}
          onChange={this.toggleAnimation}
          partialVisibility
        >
          <Lottie
            isClickToPauseDisabled
            config={animationOptions}
            playingState={paused ? 'paused' : 'playing'}
            speed={animationSpeed}
            lottieEventListeners={[
              {
                name: 'enterFrame',
                callback: event => this.pauseAnimationAtTime(event)
              },
              {
                name: 'loopComplete',
                callback: event => this.loopComplete(event)
              },

            ]}
          />
        </VisibilitySensor>
      </styles.Container>
    )
  }
}

AnimationWrapper.propTypes = propTypes
AnimationWrapper.defaultProps = defaultProps
