import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import sizeMe from 'react-sizeme'
import * as styles from './styles'
import futuriceLogo from '../../../assets/customer-logos/futurice-logo.svg'
import futuriceLogoGray from '../../../assets/customer-logos/futurice-logo-gray.svg'
import helsingoLogo from '../../../assets/customer-logos/helsingo-logo.svg'
import helsingoLogoGray from '../../../assets/customer-logos/helsingo-logo-gray.svg'
import ikeaLogo from '../../../assets/customer-logos/ikea-logo.svg'
import ikeaLogoGray from '../../../assets/customer-logos/ikea-logo-gray.svg'

import intwixtLogo from '../../../assets/customer-logos/intwixt-logo.svg'
import intwixtLogoGray from '../../../assets/customer-logos/intwixt-logo-gray.svg'

import sisuaLogo from '../../../assets/customer-logos/sisua-logo.svg'
import sisuaLogoGray from '../../../assets/customer-logos/sisua-logo-gray.svg'

import teostoLogo from '../../../assets/customer-logos/teosto-logo.svg'
import teostoLogoGray from '../../../assets/customer-logos/teosto-logo-gray.svg'

import uipathLogo from '../../../assets/customer-logos/uipath-logo.svg'
import uipathLogoGray from '../../../assets/customer-logos/uipath-logo-gray.svg'

import comcastLogo from '../../../assets/customer-logos/comcast-logo.svg'
import comcastLogoGray from '../../../assets/customer-logos/comcast-logo-gray.svg'

import postiLogo from '../../../assets/customer-logos/posti-logo.svg'
import postiLogoGray from '../../../assets/customer-logos/posti-logo-gray.svg'

import techcrunchLogo from '../../../assets/customer-logos/techcrunch-logo.svg'
import techcrunchLogoGray from '../../../assets/customer-logos/techcrunch-logo-gray.svg'

import forbesLogo from '../../../assets/customer-logos/forbes-logo.svg'
import forbesLogoGray from '../../../assets/customer-logos/forbes-logo-gray.svg'


const items = [
  {
    id: 0,
    src: uipathLogo,
    graySrc: uipathLogoGray,
    url: '/showcases?useCaseId=ticketCategorisation#ticketCategorisation',
    isExternal: false
  },
  {
    id: 1,
    src: comcastLogo,
    graySrc: comcastLogoGray,
    url: '/showcases?useCaseId=cxExperimentation#cxExperimentation',
    isExternal: false
  },
  {
    id: 2,
    src: ikeaLogo,
    graySrc: ikeaLogoGray,
    url: 'https://www.ikea.com/',
    isExternal: true
  },
  {
    id: 3,
    src: postiLogo,
    graySrc: postiLogoGray,
    url: '/showcases?useCaseId=invoiceAutomation#invoiceAutomation',
    isExternal: false
  },
  {
    id: 4,
    src: forbesLogo,
    graySrc: forbesLogoGray,
    url: 'https://www.forbes.com/sites/patrickmoorhead/2021/05/25/the-finnish-post-reinvents-itself-with-new-technologies-including-robotic-process-automation-and-gamification/',
    isExternal: true
  },
  {
    id: 5,
    src: futuriceLogo,
    graySrc: futuriceLogoGray,
    url: '/showcases?useCaseId=ticketTriage#ticketTriage',
    isExternal: false
  },
  {
    id: 6,
    src: intwixtLogo,
    graySrc: intwixtLogoGray,
    url: '/showcases?useCaseId=leadQualification#leadQualification',
    isExternal: false
  },
  {
    id: 7,
    src: helsingoLogo,
    graySrc: helsingoLogoGray,
    url: '/showcases?useCaseId=catalogueMatching#catalogueMatching',
    isExternal: false
  },
  {
    id: 8,
    src: sisuaLogo,
    graySrc: sisuaLogoGray,
    url: 'https://www.sisuadigital.com/',
    isExternal: true
  },
  {
    id: 9,
    src: teostoLogo,
    graySrc: teostoLogoGray,
    url: '/showcases?useCaseId=dataMatching#dataMatching',
    isExternal: false
  },
  {
    id: 10,
    src: techcrunchLogo,
    graySrc: techcrunchLogoGray,
    url: 'https://techcrunch.com/2019/05/07/aito-ai',
    isExternal: true
  }
]

function isMoveCardsLeftPossible(position, itemsCount, windowSize) {
  return position + itemsCount > windowSize
}

function isMoveCardsRightPossible(position) {
  return position < 0
}

class LogoCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollButtonWidth: 30,
      minCardWidth: 250,
      carouselPosition: 0,
    }
  }

  getPosition = () => {
    const { state } = this
    return state.carouselPosition
  }

  setPosition = (pos) => {
    this.setState({
      carouselPosition: pos,
    })
  }

  getCarouselWidth = () => {
    const { props, state } = this
    return props.size.width - 2 * state.scrollButtonWidth
  }

  getCarouselSize = () => {
    const { minCardWidth } = this.state
    const carouselWidth = this.getCarouselWidth()
    const floorCarouselSize = Math.floor(carouselWidth / minCardWidth)
    const carouselSize = floorCarouselSize !== 0 ? floorCarouselSize : 1
    const cardWidth = carouselWidth / carouselSize
    return { cardWidth, carouselSize }
  }

  isMoveCardsRightPossible = () => {
    return isMoveCardsRightPossible(this.getPosition())
  }

  isMoveCardsLeftPossible = () => {
    const { carouselSize } = this.getCarouselSize()
    return isMoveCardsLeftPossible(this.getPosition(), items.length, carouselSize)
  }

  moveCardsLeft = (_add) => {
    if (!this.isMoveCardsLeftPossible(_add)) {
      return
    }

    this.setPosition(this.getPosition() - 1)
  }

  moveCardsRight = () => {
    if (!this.isMoveCardsRightPossible()) {
      return
    }

    this.setPosition(this.getPosition() + 1)
  }

  onLogoMouseEnter = (index) => {
    this.setState({
      logoHover: index,
    })
  }

  onLogoMouseLeave = (index) => {
    const { state } = this
    if (state.logoHover === index) {
      this.setState({
        logoHover: null,
      })
    }
  }

  render() {
    const {
      scrollButtonWidth,
      logoHover
    } = this.state
    const cardsContainerWidth = this.getCarouselWidth()
    const { cardWidth } = this.getCarouselSize()
    const moveNRight = this.getPosition()
    const carouselTransform = `translate(${cardWidth * moveNRight}px)`

    return (
      <styles.Container>
        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsRight}
          movePossible={this.isMoveCardsRightPossible()}
        >
          <styles.ChevronLeftIcon />
        </styles.ScrollButton>

        <styles.CarouselItemsContainer width={cardsContainerWidth}>
          <styles.CarouselItems transform={carouselTransform}>
            {_.map(items, (item, index) => {
              const isLogoHovered = logoHover === index

              const onHoverCallbackProps = {
                onMouseEnter: () => this.onLogoMouseEnter(index),
                onMouseLeave: () => this.onLogoMouseLeave(index),
              }
              return (
                <styles.LogoListItem itemIndex={index} key={index}>
                  <styles.LogoContainer width={cardWidth}>
                    {item.isExternal
                      ? (
                        <a href={item.url}>
                          <styles.LogoBox {...onHoverCallbackProps}>
                            <styles.Logo
                              src={isLogoHovered ? item.src : item.graySrc}
                              alt="customer logo"
                            />
                          </styles.LogoBox>
                        </a>
                      ) : (
                        <Link to={item.url}>
                          <styles.LogoBox {...onHoverCallbackProps}>
                            <styles.Logo
                              src={isLogoHovered ? item.src : item.graySrc}
                              alt="customer logo"
                            />
                          </styles.LogoBox>
                        </Link>
                      )
                    }
                  </styles.LogoContainer>
                </styles.LogoListItem>
              )
            })}
          </styles.CarouselItems>
        </styles.CarouselItemsContainer>

        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsLeft}
          movePossible={this.isMoveCardsLeftPossible()}
        >
          <styles.ChevronRightIcon />
        </styles.ScrollButton>

      </styles.Container>
    )
  }
}

export default sizeMe({ refreshRate: 40 })(LogoCarousel)
