import styled from 'styled-components'
import { Link } from 'gatsby'
import { Col } from 'react-styled-flexboxgrid'
import { ChevronRight } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import { TextWrapper } from '../../styles/base'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`

export const SubContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
  padding: 40px 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${variables.media.sm} {
    padding: 60px 40px;
    display: block;
  }

  @media ${variables.media.md} {
    padding: 100px 120px;
    display: block;
  }
`

export const AitoShowcasesHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`
export const ShowcaseHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  color: ${variables.colors.oracle.darker};
  font-family: ${variables.fonts.body};
`
export const ShowcaseText = styled.p`
  color: ${variables.colors.oracle.darker};
`
export const ShowcaseCol = styled(Col)`
  padding-bottom: 30px;
  @media ${variables.media.sm} {
    padding-bottom: ${props => (props.rows > 1 ? '30px' : '0')};
  }
`
export const ShowcaseCard = styled.div`
  height: 100%;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 4px 20px rgba(191, 191, 191, 0.34);
  margin-bottom: 20px;
  @media ${variables.media.md} {
    padding-right: 20px;
    margin-right: 40px;
    margin-bottom: 0;
    padding: 0;
  }

  .learn-more {
    font-weight: bold;
    animation: smallSlideUp 0.5s ease-in-out;
    display: none;
  }

  &:hover {
    box-shadow: 0px 6px 14px rgba(235, 229, 229, 0.83);
    .learn-more {
      display: block;
    }
  }
`

export const ImgContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImgPlaceholder = styled.img`
  width: 60%;
  height: 80%;
`

export const StyledLink = styled(Link)`
  text-decoration: none !important;
`

export const CardTextContainer = styled.div`
  padding: 10px 20px;
`
export const LearnMore = styled.div`
  height: 20px;
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`
