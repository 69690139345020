import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-styled-flexboxgrid'
import _ from 'lodash'
import Collapse from '@kunukn/react-collapse'
import { Section, Markdown } from '..'
import * as styles from './styles'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default class FaqBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: null
    }
  }

  onToggle = (index) => {
    this.setState(state => ({ index: state.index === index ? null : index }))
  }

  render() {
    const { data } = this.props
    const { index } = this.state

    const answerText = text => (
      <styles.AnswerText>
        <Markdown>
          {text}
        </Markdown>
      </styles.AnswerText>
    )
    return (
      <styles.Container>
        <styles.SectionContainer>
          <styles.SectionRow>
            <Col md={1} xs={0} />
            <styles.HeaderCol md={5} xs={12}>
              <styles.SectionHeader>
                {data.faqHeader}
              </styles.SectionHeader>
              <styles.SectionText>
                <Markdown>
                  {data.faqText}
                </Markdown>
              </styles.SectionText>
            </styles.HeaderCol>
            <Section>
              <styles.QuestionCol md={5} xs={12}>
                <styles.QuestionsContainer>
                  {_.map(data.faq, (question, i) => {
                    const isItemActive = question.id === index
                    return (
                      <styles.QuestionWrapper key={i}>
                        <styles.QuestionHeaderContainer onClick={() => this.onToggle(question.id)}>
                          <styles.QuestionHeader>
                            {question.header}
                          </styles.QuestionHeader>
                          <styles.Chevron isItemActive={isItemActive} />
                        </styles.QuestionHeaderContainer>
                        <Collapse
                          isOpen={index === question.id}
                          render={() => answerText(question.text)}
                        />
                      </styles.QuestionWrapper>
                    )
                  })}
                </styles.QuestionsContainer>
              </styles.QuestionCol>
            </Section>
            <Col md={1} xs={0} />
          </styles.SectionRow>
        </styles.SectionContainer>
      </styles.Container>
    )
  }
}

FaqBlock.propTypes = propTypes
