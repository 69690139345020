import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as styles from './styles'

const propTypes = {
  content: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
}

const BlogCard = (props) => {
  const { content, path } = props
  return (
    <styles.BlogCard>
      {
        content.coverImg.childImageSharp.fluid
          ? (
            <styles.Link to={path}>
              <styles.BlogImg fluid={content.coverImg.childImageSharp.fluid} alt="" />
            </styles.Link>
          )
          : null
      }

      <styles.Link to={path}>
        <styles.BlogTitle>{content.frontmatter.title}</styles.BlogTitle>
      </styles.Link>

      <styles.BlogSummary>{content.frontmatter.introduction}</styles.BlogSummary>
      {
        content.tags
          ? (
            <styles.TagRow>
              {_.map(content.frontmatter.tags, (tag, i) => <styles.Tag key={i}>{`#${tag}`}</styles.Tag>)}
            </styles.TagRow>
          )
          : null
      }

      <styles.ReadMoreLink to={path}>
        Read more
        <styles.Chevron />
      </styles.ReadMoreLink>
    </styles.BlogCard>
  )
}

BlogCard.propTypes = propTypes

export default BlogCard
