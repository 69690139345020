import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'
import variables from '../../styles/variables'

const propTypes = {
  type: PropTypes.string,
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

const defaultProps = {
  type: 'plain',
  buttonColor: variables.colors.primary,
  textColor: variables.colors.secondary,
  hoverTextColor: variables.colors.silverTree.darker,
  onClick: () => null,
  size: 'medium',
  children: null,
  className: '',
}

class Button extends React.Component {
  onClick = (e) => {
    const { props } = this

    if (props.disabled) {
      e.preventDefault()
      return
    }

    if (props.onClick) {
      props.onClick(e)
    }
  }

  render() {
    const { props } = this

    return (
      <styles.Button {...props} onClick={this.onClick}>
        {props.children}
      </styles.Button>
    )
  }
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
