import React from 'react'
import _ from 'lodash'
import { PoseGroup } from 'react-pose'
import sizeMe from 'react-sizeme'
import * as styles from './styles'
import { QueryModal } from '../index'

function isMoveCardsLeftPossible(position, itemsCount, windowSize) {
  return position + itemsCount > windowSize
}

function isMoveCardsRightPossible(position) {
  return position < 0
}

// https://stackoverflow.com/questions/37440408/how-to-detect-esc-key-press-in-react-and-how-to-handle-it
const ESC_KEY = 27

class QueryCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollButtonWidth: 30,
      minCardWidth: 260,
      carouselPosition: 0,
      cardOpen: null,
      queryModalContent: null
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyUp, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyUp, false)
  }

  getPosition = () => {
    const { state } = this
    return state.carouselPosition
  }

  setPosition = (pos) => {
    this.setState({
      carouselPosition: pos,
    })
  }

  getCarouselWidth = () => {
    const { props, state } = this
    return props.size.width - 2 * state.scrollButtonWidth
  }

  getCarouselSize = () => {
    const { minCardWidth } = this.state
    const carouselWidth = this.getCarouselWidth()
    const floorCarouselSize = Math.floor(carouselWidth / minCardWidth)
    const carouselSize = floorCarouselSize !== 0 ? floorCarouselSize : 1
    const cardWidth = carouselWidth / carouselSize
    return { cardWidth, carouselSize }
  }

  isMoveCardsRightPossible = () => {
    return isMoveCardsRightPossible(this.getPosition())
  }

  isMoveCardsLeftPossible = () => {
    const { items } = this.props
    const { carouselSize } = this.getCarouselSize()
    return isMoveCardsLeftPossible(this.getPosition(), items.length, carouselSize)
  }

  moveCardsLeft = (_add) => {
    if (!this.isMoveCardsLeftPossible(_add)) {
      return
    }

    this.setPosition(this.getPosition() - 1)
  }

  moveCardsRight = () => {
    if (!this.isMoveCardsRightPossible()) {
      return
    }

    this.setPosition(this.getPosition() + 1)
  }

  moveModal = (currentId, direction) => {
    let item
    const { items } = this.props
    const indexOfCurrentItem = items.findIndex(i => i.id === currentId)
    if (direction === 'left') {
      if (currentId === items[items.length - 1].id) return
      item = items[indexOfCurrentItem + 1]
    } else {
      if (currentId === items[0].id) return
      item = items[indexOfCurrentItem - 1]
    }
    this.setState({
      queryModalContent: item,
      movingDirection: direction
    })
  }

  onKeyUp = (event) => {
    if (event.keyCode === ESC_KEY) {
      this.closeCard()
    }
  }

  onCardClick = (item, index) => {
    const { state } = this
    if (state.cardOpen !== null) {
      return
    }

    this.setState({
      cardOpen: index,
      queryModalContent: item,
      cardHover: null,
    })
  }

  onCardMouseEnter = (index) => {
    this.setState({
      cardHover: index,
    })
  }

  onCardMouseLeave = (index) => {
    const { state } = this
    if (state.cardHover === index) {
      this.setState({
        cardHover: null,
      })
    }
  }

  onClickOut = () => {
    this.closeCard()
  }

  closeCard = () => {
    this.setState({
      queryModalContent: null,
      cardOpen: null,
      cardHover: null,
    })
  }

  render() {
    const {
      scrollButtonWidth, cardOpen, cardHover, queryModalContent, movingDirection
    } = this.state
    const { items } = this.props
    const cardsContainerWidth = this.getCarouselWidth()
    const { cardWidth } = this.getCarouselSize()
    const moveNRight = this.getPosition()
    const carouselTransform = `translate(${cardWidth * moveNRight}px)`
    const isAnyCardOpen = cardOpen !== null

    return (
      <styles.Container>
        <styles.BackgroundFrame onClick={this.onClickOut} pose={isAnyCardOpen ? 'enter' : 'exit'} />
        <PoseGroup>
          {isAnyCardOpen
          && <QueryModal
            key={queryModalContent.id}
            items={items}
            item={queryModalContent}
            closeCard={this.closeCard}
            moveModal={this.moveModal}
            movingDirection={movingDirection}
          />
          }
        </PoseGroup>
        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsRight}
          movePossible={this.isMoveCardsRightPossible()}
        >
          <styles.ChevronLeftIcon />
        </styles.ScrollButton>

        <styles.UseCaseCardsContainer width={cardsContainerWidth}>
          <styles.UseCaseCards transform={carouselTransform}>
            {_.map(items, (item, itemIndex) => {
              const isCardOpen = cardOpen === itemIndex
              const isCardHovered = cardHover === itemIndex

              const onHoverCallbackProps = isCardOpen ? {} : {
                onMouseEnter: () => this.onCardMouseEnter(itemIndex),
                onMouseLeave: () => this.onCardMouseLeave(itemIndex),
              }
              return (
                <styles.CardListItem itemIndex={itemIndex} open={isCardOpen} key={itemIndex}>
                  <styles.CardContainer width={cardWidth}>
                    <styles.UseCaseCard
                      open={isCardOpen}
                      hover={isCardHovered}
                      onClick={() => this.onCardClick(item, itemIndex)}
                      {...onHoverCallbackProps}
                    >
                      <PoseGroup>
                        {
                          (isCardHovered && !isCardOpen)
                            ? <styles.UseCaseCardOverlay key="overlay"><span>See query</span></styles.UseCaseCardOverlay>
                            : null
                        }
                      </PoseGroup>
                      <styles.UseCaseCardTitle pose={isCardHovered ? 'hover' : 'init'}>
                        {item.title}
                      </styles.UseCaseCardTitle>
                    </styles.UseCaseCard>
                  </styles.CardContainer>
                </styles.CardListItem>
              )
            })}
          </styles.UseCaseCards>
        </styles.UseCaseCardsContainer>

        <styles.ScrollButton
          width={scrollButtonWidth}
          onClick={this.moveCardsLeft}
          movePossible={this.isMoveCardsLeftPossible()}
        >
          <styles.ChevronRightIcon />
        </styles.ScrollButton>

      </styles.Container>
    )
  }
}


export default sizeMe({ refreshRate: 40 })(QueryCarousel)
