import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
}
const defaultProps = {
  external: false
}
const CustomLink = (props) => {
  const {
    to,
    children,
    external
  } = props

  const otherProps = _.omit(props, ['to', 'external', 'children'])

  if (external) {
    return (
      <styles.ExternalLink href={to} {...otherProps}>
        {children}

        <styles.ExternalLinkIcon />
      </styles.ExternalLink>
    )
  }

  return (
    <styles.Link to={to} {...otherProps}>
      {children}
    </styles.Link>
  )
}

CustomLink.propTypes = propTypes
CustomLink.defaultProps = defaultProps

export default CustomLink
