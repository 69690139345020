import React from 'react'
import * as styles from './styles'
import { Markdown } from '..'

export default (props) => {
  const { text, url } = props

  if (url) {
    return (
      <styles.Anchor href={url}>
        <styles.Container hasUrl>
          <styles.Banner>
            <Markdown>{text}</Markdown>
          </styles.Banner>
        </styles.Container>
      </styles.Anchor>
    )
  }
  return (
    <styles.Container>
      <styles.Banner>
        <Markdown>{text}</Markdown>
      </styles.Banner>
    </styles.Container>
  )
}
