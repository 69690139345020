import styled from 'styled-components'
import variables from '../../styles/variables'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 40px;

  @media ${variables.media.sm} {
    padding-top: 0;
    padding-bottom: 100px;
    margin-top: 80px;
  }
`

export const Header = styled(Heading)`
  color: ${variables.colors.silverTree.dark};
  margin-bottom: 20px;
`
export const HelpText = styled.p`
  margin-top: 8px;
  color: ${variables.colors.jade.medium};
`
export const Time = styled.p`
  font-size: 2.5rem;
  color: ${variables.colors.silverTree.dark};
  text-align: left;
  font-family: ${variables.fonts.header};
  font-weight: 700;
  margin: 20px 0 4px 0 !important;
  white-space: nowrap;
  word-spacing: -0.8rem;

  @media ${variables.media.sm} {
    margin: 0 0 4px 0 !important;
    text-align: right;
  }

  @media only percy {
    visibility: hidden;
  }
`
export const Location = styled.p`
  color: ${variables.colors.silverTree.medium};
  font-family: ${variables.fonts.header};
  max-width: 200px;
  text-align: left;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;

  @media ${variables.media.sm} {
    text-align: right;
    float: right;
  }
`
