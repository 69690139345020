import styled from 'styled-components'
import { Circle } from '@styled-icons/fa-solid'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 300px;
  border-radius: 7px;
  overflow: hidden;
  @media ${variables.media.sm} {
    width: 340px;
  }
  @media ${variables.media.md} {
    width: 480px;
  }
  @media ${variables.media.lg} {
    width: 500px;
  }
`
export const TopBarCirclesContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`
export const TopBar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  background-color: ${props => props.backgroundColor};
  padding: 10px;
`
export const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const Title = styled.p`
  padding: 0;
  margin: 0;
  font-size: 0.9rem;

  ${props => `
    color: ${props.color}
  `}
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  background: ${props => props.background};
  @media ${variables.media.md} {
    padding: 10px 20px;
  }
`
export const CircleIcon = styled(Circle)`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`
export const RedCircle = styled(CircleIcon)`
  color: #F85955;
`
export const YellowCircle = styled(CircleIcon)`
  color: #FFC12E;
`
export const GreenCircle = styled(CircleIcon)`
  color: #28CA40;
`
