import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  color: white;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const BlockSubContainer = styled.div`
  padding: 40px 20px;
  background: ${variables.colors.silverTree.dark};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${variables.media.sm} {
    padding: 60px 40px;
    display: block;
  }

  @media ${variables.media.md} {
    padding: 100px 120px;
    display: block;
  }
`


export const BlockHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-family: ${variables.fonts.body};
  color: white;
  margin-bottom: 60px !important;
`

export const CardContainer = styled.div`
  min-height: 270px;
  padding: 10px 25px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;

  .learn-more {
    font-weight: bold;
    animation: smallSlideUp 0.5s ease-in-out;
    display: none;
  }

  &:hover {
    border: 1px solid white;
    .learn-more {
      display: block;
    }
  }
`

export const StyledLinkCard = styled.a`
  color: white;
  animation: smallSlideUp 0.5s ease-in-out;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: white;
  }
`

export const CardHeader = styled(Heading)`
  ${headerStyles.headerXXSmall}
  font-family: ${variables.fonts.body};
  margin-bottom: 1.5rem;
`

export const CardText = styled.div`
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`

export const CTAHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  color: white;
  font-family: ${variables.fonts.body};
`

export const CallToActionContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(0, 184, 86, 0.12);
`
