import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter/light'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const QueryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 275px;
  padding: 0 10px;
  border-radius: 5px;

  @media ${variables.media.sm} {
    margin: 10px 0;
  }
`

export const BrowserContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

export const BackendWindow = styled.div`
`
export const SwitchButtonsContainer = styled.div`
  height: 30px;
`
export const SwitchButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

export const SwitchButton = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => (props.active ? '#eeeeee' : '#555555')};
  cursor: pointer;
  margin-right: 5px;

`

export const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  animation: fadeIn 0.5s ease-in-out;
`

export const ResponseSyntaxHighlighter = styled(SyntaxHighlighter)`
  animation: fadeIn 0.5s ease-in-out;
`

export const CodeContainer = styled.div`
  padding-top: 5px;
  margin-top: 5px;
  animation: fadeIn 2s ease-in-out;
`

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.85rem;
  background: ${variables.colors.pearlBrush.dark};
  color: white;
  padding: 4px 10px;
  border-radius: 15px;
`
