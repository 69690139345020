import styled from 'styled-components'
import Img from 'gatsby-image'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const QuoteBox = styled.div`
  background: ${props => (props.background ? props.background : 'rgba(255, 169, 211, 0.3)')};
  width: 100%;
  height: 200px;
  border-radius: 25px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0 30px;
  color: ${props => (props.color ? props.color : variables.colors.oracle.dark)};
  font-weight: bold;
  font-family: ${variables.fonts.header};
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${variables.media.md} {
    font-size: 1rem;
  }
`

export const QuoteAuthorWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const QuoteAuthor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -20px;
`

export const Avatar = styled(Img)`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  filter: grayscale(100%);
`

export const AuthorInfo = styled.div`
  margin-left: 15px;

  p {
    margin: 0;
    font-size: 0.85rem;
    color: ${props => (props.color ? props.color : variables.colors.oracle.dark)};
    font-style: italic;
  }
`
