import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/octicons/ChevronRight'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

`

export const Right = styled.div`
  padding: 0 20px 0 30px;
`

export const AnimationContainer = styled.div`
  height: 70px;
  width: 70px;
  flex-shrink: 0;
`

export const Header = styled(Heading)`
  ${headerStyles.headerXSmall}
  font-weight: normal;
  margin: 0 0 8px 0;
`

export const IntroText = styled.div`
  padding-right: 10px;
  margin-bottom: 10px;
`
export const ReadMoreLink = styled.a`
  color: ${variables.colors.jade.light} !important; /*no idea why important is needed here*/
`

export const Chevron = styled(ChevronRight)`
  transition: transform 300ms ease-in-out;
  width: 12px;
  height: 12px;
  margin-left: 5px;
`
