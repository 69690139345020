import styled from 'styled-components'
import variables from '../../styles/variables'
import { CustomLink as BaseCustomLink } from '..'

export const CustomLink = styled(BaseCustomLink)`
  padding: 6px 20px !important;
  display: block;
`

export const Container = styled.div`
  width: 100%;
  padding: 0;
  white-space: nowrap;
  color: ${variables.colors.dark};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${props => props.first && `
    ${CustomLink} {
      padding-top: 0;
    }
  `}

  ${props => props.last && `
    ${CustomLink} {
      padding-bottom: 0;
    }
  `}
`
