import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.string,
}

const defaultProps = {
  className: '',
  placeholder: '',
  inputStyle: 'round',
}

class Input extends React.Component {
  render() {
    const { props } = this
    return (
      <styles.Input {...props} onClick={this.onClick}>
        {props.children}
      </styles.Input>
    )
  }
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
