import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  src: PropTypes.string.isRequired,
  caption: PropTypes.string,
  altText: PropTypes.string,
  creditUrl: PropTypes.string,
  creditText: PropTypes.string,
  creditPhrase: PropTypes.string,
}

const defaultProps = {
  caption: null,
  altText: null,
  creditUrl: null,
  creditText: null,
  creditPhrase: null,
}

const ESC_KEY = 27

export default class BlogImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isImgZoomed: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.zoomOut, false)
    document.addEventListener('keydown', this.onKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.zoomOut, false)
    document.removeEventListener('keydown', this.onKeyUp, false)
  }

  toggleZoom = () => {
    const { state } = this
    return (
      state.isImgZoomed ? this.zoomOut() : this.zoomIn()
    )
  }

  zoomIn = () => {
    this.setState({ isImgZoomed: true })
  }

  zoomOut = () => {
    this.setState({ isImgZoomed: false })
  }

  onKeyUp = (event) => {
    if (event.keyCode === ESC_KEY) {
      this.zoomOut()
    }
  }

  render() {
    const { state } = this
    const {
      src, caption, altText, creditUrl, creditText, creditPhrase
    } = this.props
    const { isImgZoomed } = state
    const pose = isImgZoomed ? 'zoom' : 'init'

    return (
      <styles.Container>
        <styles.ImageWrapper isImgZoomed={isImgZoomed} onClick={this.toggleZoom}>
          <styles.ImageFrame pose={pose} />
          <styles.BlogImage pose={pose} isImgZoomed={isImgZoomed} src={src} alt={altText} />
        </styles.ImageWrapper>
        {caption
          && <styles.Caption>{caption}</styles.Caption>
        }
        {(creditUrl && creditText)
          && (
            <styles.Credit>
              {`${creditPhrase || 'Photo by'} `}
              <a href={creditUrl} target="_blank" rel="noopener noreferrer">
                {creditText}
              </a>
            </styles.Credit>
          )
        }
      </styles.Container>
    )
  }
}

BlogImage.propTypes = propTypes
BlogImage.defaultProps = defaultProps
