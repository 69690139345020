import styled from 'styled-components'
import Color from 'color'
import { ChevronRight, ChevronLeft } from '@styled-icons/octicons'
import variables from '../../styles/variables'

const cardBorderRadius = '8px'

export const Container = styled.div`
  max-width: 1200px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`

export const UseCaseCardsContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  width: ${props => props.width}px;
`

export const UseCaseCards = styled.ul`
  display: flex;
  flex: 1 0 auto;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${props => props.transform};
`

export const CardListItem = styled.li`
  transition: top 0.3s ease;
  position: relative;
  top: 0;
  ${props => !props.disable && `
    &:hover {
      top: -5px;
    }
  `}
`

export const ScrollButton = styled.a`
  color: #8C8C8C;
  width: ${props => props.width}px;
  text-align: center;
  ${props => props.movePossible && `

    color: ${variables.colors.jade.medium};
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: ${Color(variables.colors.jade.medium).darken(0.3).string()};
    }
  `}
`

export const CardContainer = styled.div`
  width: ${props => props.width}px;
  height: 125px;
`

export const UseCaseCardTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

export const UseCaseCard = styled.div`
  display: inline-block;
  width: 230px;
  height: 125px;
  border-radius: ${cardBorderRadius};
  text-align: center;
  background: ${props => (props.disabled ? variables.colors.oracle.light : variables.colors.jade.medium)};
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.1);

  ${props => !props.disabled && `
    &:hover {
      cursor: pointer;
      box-shadow: 0 8px 10px 3px rgba(0, 0, 0, 0.12);
    }
  `}
}
`

export const ChevronRightIcon = styled(ChevronRight)`
  width: 25px;
  height: 25px;
`
export const ChevronLeftIcon = styled(ChevronLeft)`
  width: 25px;
  height: 25px;
`
