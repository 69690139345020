import React from 'react'
import Slider from 'rc-slider'
import * as styles from './styles'

const TooltipSlider = Slider.createSliderWithTooltip(Slider)

const CustomSlider = (props) => {
  return (
    <styles.Container>
      <TooltipSlider {...props} />
    </styles.Container>
  )
}

export default CustomSlider
