import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'
import _ from 'lodash'
import * as styles from './styles'
import { Section } from '..'
import posti from '../../../assets/customer-logos/posti-logo.svg'
import uipath from '../../../assets/customer-logos/uipath-logo.svg'
import helsingo from '../../../assets/customer-logos/helsingo-logo.svg'
import sisua from '../../../assets/customer-logos/sisua-logo.svg'
import columbiaroad from '../../../assets/customer-logos/columbia-road-logo.svg'
import futurice from '../../../assets/customer-logos/futurice-logo.svg'
import qfactory from '../../../assets/customer-logos/qfactory-logo.svg'
import gridpane from '../../../assets/customer-logos/gridpane-logo.svg'
import sofigate from '../../../assets/customer-logos/sofigate-logo.svg'
import teosto from '../../../assets/customer-logos/teosto-logo.svg'
import tilisi from '../../../assets/customer-logos/tilisi-logo.svg'

const showcase = PropTypes.shape({
  src: PropTypes.any,
  header: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string
})

const propTypes = {
  header: PropTypes.string.isRequired,
  showcases: PropTypes.arrayOf(showcase).isRequired
}

// To add an new image, import the svg file and add it in here
const images = {
  posti,
  uipath,
  helsingo,
  sisua,
  columbiaroad,
  futurice,
  qfactory,
  gridpane,
  tilisi,
  sofigate,
  teosto
}

const AitoShowcases = ({ showcases, header }) => {
  return (
    <styles.Container>
      <styles.SubContainer>
        <Row>
          <Col xs={12}>
            <styles.AitoShowcasesHeader>
              {header}
            </styles.AitoShowcasesHeader>
          </Col>
        </Row>
        <Section>
          <Row>
            {_.map(showcases, (item, i) => {
              return (
                <styles.ShowcaseCol sm={4} xs={12} key={i} rows={showcases.length / 3}>
                  <styles.StyledLink to={item.url}>
                    <styles.ShowcaseCard>
                      <styles.ImgContainer>
                        <styles.ImgPlaceholder src={images[item.image]} alt="use case" />
                      </styles.ImgContainer>
                      <styles.CardTextContainer>
                        <styles.ShowcaseHeader>
                          {item.header}
                        </styles.ShowcaseHeader>
                        <styles.ShowcaseText>
                          {item.text}
                        </styles.ShowcaseText>
                        <styles.LearnMore>
                          <div className="learn-more">
                            Learn more
                            <styles.Chevron />
                          </div>
                        </styles.LearnMore>
                      </styles.CardTextContainer>
                    </styles.ShowcaseCard>
                  </styles.StyledLink>
                </styles.ShowcaseCol>
              )
            })}
          </Row>
        </Section>
      </styles.SubContainer>
    </styles.Container>
  )
}

AitoShowcases.propTypes = propTypes

export default AitoShowcases
