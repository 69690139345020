import styled from 'styled-components'

export const Textarea = styled.textarea`
  width: 100%;
  font-weight: 400;
  border: 0;
  border-radius: 3px;
  min-height: 140px;
  padding: 20px 24px;
  max-width: 400px;
  font-size: 1rem;
`
