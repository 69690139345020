import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Button } from '..'
import * as styles from './styles'

const propTypes = {
  header: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  target: PropTypes.string,
  data: PropTypes.object.isRequired
}

const defaultProps = {
  header: undefined,
  buttonUrl: undefined,
  buttonText: undefined,
  target: undefined,
}

const SimpleCallToActionBanner = ({
  header, buttonUrl, buttonText, target, data
}) => {
  const internalLink = (
    <Link to={buttonUrl || data.buttonUrl}>
      <Button>{buttonText || data.buttonText}</Button>
    </Link>
  )
  const outLink = (
    <a target={target} rel="noopener noreferrer" href={buttonUrl || data.buttonUrl}>
      <Button>{buttonText || data.buttonText}</Button>
    </a>
  )
  return (
    <styles.Container>
      <styles.TextWrapper>
        <styles.Header>
          {header || data.header}
        </styles.Header>
        { target ? outLink : internalLink }
      </styles.TextWrapper>
    </styles.Container>
  )
}

SimpleCallToActionBanner.propTypes = propTypes
SimpleCallToActionBanner.defaultProps = defaultProps


export default props => (
  <StaticQuery
    query={graphql`
      query GetSimpleCallToAction {
        allYaml(filter: {file: {eq: "simple-call-to-action"}}) {
          edges {
            node {
              header
              buttonText
              buttonUrl
            }
          }
        }
      }
    `
    }
    render={(data) => {
      const { allYaml } = data
      const { edges } = allYaml
      const { node } = _.first(edges)
      return (
        <SimpleCallToActionBanner
          data={node}
          header={props.header}
          buttonUrl={props.buttonUrl}
          buttonText={props.buttonText}
          target={props.target}
        />
      )
    }}
  />
)
