import styled from 'styled-components'
import { ChevronRight, ChevronLeft } from '@styled-icons/octicons'
import { Link } from 'gatsby'
import variables from '../../styles/variables'

export const BlogList = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
`
export const BlogItem = styled.li`
  width: 100%;
  padding-bottom: 50px;
  @media ${variables.media.sm} {
    flex: 0 0 50%;
    padding: 10px 20px 50px 20px;
  }
  @media ${variables.media.lg} {
    flex: 0 0 33.3333%;
  }
`

export const PageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 0 50px 0;
`

export const PageLink = styled(Link)`
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  padding: 0.5rem;
  text-decoration: 'none';
  color: ${props => props.$isSelected && '#ffffff'} !important;
  background: ${props => props.$isSelected && variables.colors.jade.medium};
  border-radius: 3px;
`

export const NextPage = styled(ChevronRight)`
  width: 20px;
  height: 20px;
`

export const PrevPage = styled(ChevronLeft)`
  width: 20px;
  height: 20px;
`
