import styled from 'styled-components'

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`
export const LabelText = styled.span`
  margin-left: 8px;
  color: currentColor;
`
export const Checkbox = styled.input`
  margin: 0;
  position: relative;
  top: 2px;
  width: 20px;
  height: 20px;
`
