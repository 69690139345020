import styled from 'styled-components'
import { ChevronDown, ChevronUp } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;

  @media ${variables.media.md} {
    padding-right: 20px;
  }
`

export const TextContainer = styled.div`
  text-align: justify;
`

export const Card = styled.div`
  position: relative;
  background-color: #f9f9f9;
  border-left: 5px solid ${props => props.color};
  border-radius: 3px;
  padding: 25px;
  transition: all 0.5s ease;
  cursor: pointer;
  @media ${variables.media.md} {
    height: 100%;
  }
`

export const CardHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  margin: 15px 0;
  color: ${variables.colors.oracle.dark};
`

export const LinkRow = styled.div`
  margin: 20px 0;
  color: ${variables.colors.silverTree.medium};
  cursor: pointer;
  display: flex;
  align-items: center;
  @media ${variables.media.md} {
    margin: 0;
  }
`

export const ChevronDownIcon = styled(ChevronDown)`
  width: 15px;
  height: 15px;
  margin-left: 5px;
`

export const ChevronUpIcon = styled(ChevronUp)`
  width: 15px;
  height: 15px;
  margin-left: 5px;
`

export const Introduction = styled.p`
  text-align: justify;
`

export const UseCaseImgContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UseCaseImg = styled.img`
  width: 100%;
  max-width: 250px;
  height: 80px;
  margin-top: 20px;
  margin-bottom: 30px;
  @media ${variables.media.md} {
    margin: 0;
  }
`

export const Tag = styled.span`
  color: ${variables.colors.jade.medium};
  margin-right: 10px;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  a {
    margin-right: 20px;
  }
`
export const StatisticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;      
  justify-content: center;
  margin-bottom: 30px;
  @media ${variables.media.md} {
    justify-content: space-between;
    padding: 0 50px;
  }
`

export const Statistic = styled.div`
  max-width: 150px;
  @media ${variables.media.md} {
    max-width: 120px;
  }
`

export const SingleStatistic = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
export const BigText = styled.p`
  font-size: 1.7rem;
  font-weight: 600;
  color: ${variables.colors.jade.medium};
  font-family: ${variables.fonts.header};
`

export const SmallText = styled.p`
  font-family: ${variables.fonts.header};
  font-size: 0.8rem;
  ${props => props.marginLeft && 'margin-left: 10px;'}
`

export const QuoteContainer = styled.div`
  @media ${variables.media.sm} {
    justify-content: space-between;
    padding: 0 30px;
  }
`
