import React from 'react'
import _ from 'lodash'
import { stripIndent } from 'common-tags/es'
import jsonStringify from '@aitodotai/json-stringify-pretty-compact'
import dark from 'react-syntax-highlighter/dist/styles/hljs/dark'
import { BrowserWindow } from '..'
import config from '../../config'
import * as styles from './styles'

const createCodeSnippet = (text) => {
  return stripIndent`
    $ curl -X POST \\
      https://public-1.${config.AITO_APP_HOST_NAME}/api/v1/_predict \\
      -H "content-type: application/json" \\
      -H "x-api-key: bvss2i2dIkaWUfBCdzEO89LpxUkwO3A24hYg8MBq" \\
      -d '
      {
        "from": "invoice_data",
        "where": {
          "Inv_Amt": 83.24,
          "Item_Description": "File Management",
          "Vendor_Code": "VENDOR-1676"
        },
        ${text}
      }'
    `
}

function createResponseSnippet() {
  const response = {
    hits: [
      {
        $p: 0.9481763942842365,
        field: 'GL_Code',
        feature: 'GL-6050100'
      },
      {
        $p: 0.04332857862446604,
        field: 'GL_Code',
        feature: 'GL-6060100'
      },
    ]
  }

  return jsonStringify(response, { maxLength: 0, indent: 2 })
}

const backendSyntaxStyle = _.merge({}, _.cloneDeep(dark), {
  hljs: {
    background: 'none',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: 0,
    margin: '0.5rem 0',
  }
})

class AnimatedBackEndWindow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showResponse: false,
      showButtons: false,
      typingText: ''
    }
  }

  componentDidMount() {
    this.startTypingTimeout = setTimeout(() => { this.type('"predict": "GL_Code"', 0) }, 2000)
  }

  componentWillUnmount() {
    if (this.resetTypingTimeout) {
      clearTimeout(this.resetTypingTimeout)
    }
    if (this.showResponseTimeout) {
      clearTimeout(this.showResponseTimeout)
    }
    if (this.typeTimeout) {
      clearTimeout(this.typeTimeout)
    }
    if (this.startTypingTimeout) {
      clearTimeout(this.startTypingTimeout)
    }
  }

  type = (text, index) => {
    if (text.length <= index) {
      this.setState({ typingText: text }, () => {
        this.showResponseTimeout = setTimeout(
          () => this.setState({
            showResponse: true,
            showButtons: true
          }),
          1700
        )
      })
      return
    }

    this.setState({ typingText: text.substring(0, index + 1) })

    const rand = Math.floor(Math.random() * (100))
    this.typeTimeout = setTimeout(() => { this.type(text, index + 1) }, rand)
  }

  resetTyping = () => {
    setTimeout(
      () => this.setState(
        {
          typingText: '',
          showResponse: false
        },
        () => this.type('"predict": "GL_Code"', 0)
      ),
      2000
    )
  }

  showQuery = () => {
    this.setState({ showResponse: false })
  }

  showResponse = () => {
    this.setState({ showResponse: true })
  }

  render() {
    const { state } = this
    const {
      showResponse,
      showButtons,
      typingText
    } = state

    const query = (
      <styles.CodeContainer>
        <styles.Label>Aito query:</styles.Label>
        <styles.StyledSyntaxHighlighter language="bash" style={backendSyntaxStyle}>
          {createCodeSnippet(typingText)}
        </styles.StyledSyntaxHighlighter>
      </styles.CodeContainer>
    )

    const response = (
      <styles.CodeContainer>
        <styles.Label>Response (187 msec):</styles.Label>
        <styles.ResponseSyntaxHighlighter language="javascript" style={backendSyntaxStyle}>
          {createResponseSnippet()}
        </styles.ResponseSyntaxHighlighter>
      </styles.CodeContainer>
    )

    const switchButtons = (
      <styles.SwitchButtonsWrapper>
        <styles.SwitchButton active={!showResponse} onClick={this.showQuery} />
        <styles.SwitchButton active={showResponse} onClick={this.showResponse} />
      </styles.SwitchButtonsWrapper>
    )

    return (
      <styles.Container>
        <styles.BrowserContainer>
          <styles.BackendWindow>
            <BrowserWindow
              topBarBgColor="#454545"
              contentBgColor="#393939"
              titleColor="#eee"
              title="Automate purchase invoices"
            >
              <styles.QueryBox>
                {showResponse ? response : query}
              </styles.QueryBox>
            </BrowserWindow>
            <styles.SwitchButtonsContainer>
              {showButtons && switchButtons}
            </styles.SwitchButtonsContainer>
          </styles.BackendWindow>
        </styles.BrowserContainer>
      </styles.Container>
    )
  }
}

export default AnimatedBackEndWindow
