import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as styles from './styles'


const stepsProps = {
  children: PropTypes.node.isRequired,
}

export function Steps(props) {
  function createSection(child, index) {
    return React.cloneElement(child, {
      key: index,
      index
    })
  }
  const { children } = props
  const c = _.map(children, (child, index) => createSection(child, index))
  return (
    <styles.StepsContainer>
      {c}
    </styles.StepsContainer>
  )
}

const stepProps = {
  header: PropTypes.string.isRequired,
  index: PropTypes.number,
  children: PropTypes.node.isRequired,
  stepNumberBgColor: PropTypes.string,
}
const defaultStepProps = {
  index: 0,
  stepNumberBgColor: 'white'
}

export function StepsSection(props) {
  const { header, children, index, stepNumberBgColor } = props
  return (
    <styles.StepContainer>
      <Row>
        <Col sm={12} xs={12}>
          <styles.StepHeader>
            <styles.StepNumber bgColor={stepNumberBgColor}>{index + 1}</styles.StepNumber>
            <styles.HeaderSpan>{header}</styles.HeaderSpan>
          </styles.StepHeader>
        </Col>
      </Row>
      <styles.ChildRow>
        <styles.ChildCol sm={12} xs={12}>
          {children}
        </styles.ChildCol>
      </styles.ChildRow>
    </styles.StepContainer>
  )
}

Steps.propTypes = stepsProps
StepsSection.propTypes = stepProps
StepsSection.defaultProps = defaultStepProps
