import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  padding-bottom: 40px;

  @media ${variables.media.md} {
    padding-right: 20px;
  }
`

export const Card = styled.div`
  position: relative;
  background-color: #f9f9f9;
  border-left: 5px solid ${props => props.color};
  border-radius: 3px;
  padding: 25px;
  transition: all 0.5s ease;

  @media ${variables.media.md} {
    height: 100%;
  }
`

export const CardHeader = styled(Heading)`
  ${headerStyles.headerXXSmall}

  font-family: ${variables.fonts.body};
  margin: 15px 0;
`

export const Endpoint = styled.div`
  width: 70px;
  margin: 5px 0;
  background-color: grey;
  font-weight: bold;
  border-radius: 10px;
  text-align: center;

  & > a {
    color: white;
    &:visited {
      color: white;
    }
  }
`

export const LinkRow = styled.div`
  margin: 20px 0;

  @media ${variables.media.md} {
    margin: 0;
  }
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`

export const Introduction = styled.p`
  color: grey;
`

export const InfoHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  font-size: 1rem;
  font-family: ${variables.fonts.body};
  color: #707070;
  margin: 20px 0 10px 0;
`

export const InfoText = styled.div`
  color: grey;
  margin: 5px 0;
  font-size: 0.9rem;
`
