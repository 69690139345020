import React from 'react'
import MarkdownToJsx from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import _ from 'lodash'

const propTypes = {
  children: PropTypes.node.isRequired
}

const defaultOpts = {
  overrides: {
    a: ({ href, children, ...props }) => {
      return _.first(href) === '/'
        ? <Link to={href}>{children}</Link>
        : <a href={href} target="_blank" rel="noreferrer noopener" {...props}>{children}</a>
    }
  }
}

const Markdown = (props) => {
  const { children } = props

  return (
    <MarkdownToJsx
      options={defaultOpts}
    >
      {children}
    </MarkdownToJsx>
  )
}

Markdown.propTypes = propTypes

export default Markdown
