import React from 'react'
import PropTypes from 'prop-types'
import { Markdown } from '..'
import * as styles from './styles'


const propTypes = {
  data: PropTypes.object.isRequired
}

const AlternatingStories = ({ data }) => {
  const content = data.alternatingStoryImages
  return (
    <styles.Container>
      {content.map(entry => (
        <styles.EntryRow key={entry.header}>
          <styles.EntryCol md={6} xs={12}>
            <styles.StoryHeader>{entry.header}</styles.StoryHeader>
            <Markdown>{entry.text}</Markdown>
          </styles.EntryCol>
          <styles.EntryCol md={1} />
          <styles.EntryCol md={5} xs={12}>
            <styles.StyledImage fluid={entry.src.childImageSharp.fluid} alternative="image" />
          </styles.EntryCol>
        </styles.EntryRow>
      ))}
    </styles.Container>
  )
}

AlternatingStories.propTypes = propTypes
export default AlternatingStories
