import React from 'react'
import _ from 'lodash'
import { PoseGroup } from 'react-pose'
import PropTypes from 'prop-types'
import Collapse from '@kunukn/react-collapse'
import { Col, Row } from 'react-styled-flexboxgrid'
import * as styles from './styles'
import { Section, Button, MediaQuery, Markdown } from '..'
import variables from '../../styles/variables'

const propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  reverse: PropTypes.bool,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string
}

const defaultProps = {
  reverse: false,
  imageWidth: '250px',
  imageHeight: '300px'
}

export default class CollapsibleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0
    }
  }

  onToggle = (index) => {
    this.setState(state => ({ index: state.index === index ? state.index : index }))
  }

  render() {
    const {
      items, header, images, reverse, imageWidth, imageHeight
    } = this.props
    const { index } = this.state

    function renderButton() {
      return (
        _.map(items, (item, i) => {
          return item.button && (i === index) && (
            <a href={item.button.url} target="_blank" rel="noopener noreferrer" key={i}>
              <Button size="small" disabled={item.button.disabled}>
                {item.button.text}
              </Button>
            </a>
          )
        })
      )
    }

    function createContent(text) {
      return (
        <React.Fragment>
          <Markdown>
            {text}
          </Markdown>
          <MediaQuery query={variables.media.sm}>
            {(matches) => {
              if (matches) {
                return null
              }

              return renderButton()
            }}
          </MediaQuery>
        </React.Fragment>
      )
    }

    return (
      <styles.Container>
        <styles.SubContainer>
          <Row>
            <Col xs={12}>
              <styles.BlockHeader>
                {header}
              </styles.BlockHeader>
            </Col>
          </Row>
          <Section>
            <Row reverse={reverse}>
              <styles.ImageCol sm={6} xs={12}>
                <styles.ImageContainer>
                  <PoseGroup>
                    <styles.DisplayImage
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      key={index}
                      src={images[index]}
                    />
                  </PoseGroup>
                  <MediaQuery query={variables.media.sm}>
                    {(matches) => {
                      if (matches) {
                        return renderButton()
                      }

                      return null
                    }}
                  </MediaQuery>
                </styles.ImageContainer>
              </styles.ImageCol>
              <Col sm={6} xs={12}>
                {_.map(items, (item, i) => {
                  const isItemActive = i === index
                  return (
                    <styles.ItemContainer key={i} isOpen={isItemActive}>
                      <styles.ItemHeader
                        onClick={() => this.onToggle(i)}
                      >
                        {item.header}
                      </styles.ItemHeader>
                      <Collapse
                        isOpen={index === i}
                        render={() => createContent(item.text)}
                      />
                    </styles.ItemContainer>
                  )
                })}
              </Col>
            </Row>
          </Section>
        </styles.SubContainer>
      </styles.Container>
    )
  }
}

CollapsibleList.propTypes = propTypes
CollapsibleList.defaultProps = defaultProps
