import styled from 'styled-components'
import { Check } from '@styled-icons/fa-solid'
import { Info } from '@styled-icons/material'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import variables from '../../styles/variables'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 15px;
  margin-bottom: 20px;
`

export const CardHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  font-family: ${variables.fonts.body};
`
export const ImageContainer = styled.div``

export const CheckIcon = styled(Check)`
  width: 20px;
  color: ${variables.colors.jade.medium};
  margin-right: 15px;
`

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  color: ${variables.colors.jade.medium};
`

export const CardText = styled.div`
  min-height: 60px;
  text-align: center;
  padding: 0 30px;
  p {
    margin: 0;
    font-weight: 300;
  }
`
export const PriceContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`

export const PriceText = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${variables.colors.silverTree.medium};
  margin-right: 5px;
`

export const PriceInfo = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${variables.colors.oracle.dark};
`

export const BigPlan = styled.div`
  padding: 0 40px;
`

export const BigPlanText = styled.p`
  font-size: 1.3rem;
  text-align: center;
`

export const FeatureContainer = styled.div`
  margin-top: 30px;
`

export const Feature = styled.div`
  margin-bottom: 15px;
  display: flex;
`

export const FeatureText = styled.span`
  font-weight: bold;
`

export const FeatureInfo = styled.span`
  color: #7C7C7C;
`
