import styled from 'styled-components'
import variables from '../../styles/variables'
import { TextWrapper as BaseTextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'

export const Container = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(${props => props.backgroundImg});
  background-repeat:no-repeat; 
  background-size:cover; 
  background-position:center;
  height: 95vh;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  width: 100%;
  flex: 1;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const TextWrapper = styled(BaseTextWrapper)`
  max-width: 85%;
`

export const CenteredHeader = styled(Heading)`
  ${headerStyles.headerLarge}
  color: white;
  text-align: center;
  font-weight: 600;
  font-family: ${variables.fonts.body};
  word-spacing: normal !important;
  margin-bottom: 1rem;
  animation: slideUp 0.5s ease-in-out;
`
export const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  a, a:visited {
    color: rgba(255,255,255,.6);
    text-decoration: none;
  }
`
export const Tag = styled.p`
  color: rgba(255,255,255,.6);
  font-size: 1.2rem;
  margin: 0 10px 0 0;
  animation: slideUp 0.5s ease-in-out 0.1s;
  animation-fill-mode: forwards;  
  opacity: 0;
  @media ${variables.media.sm} {
    font-size: 1.5rem;
  }
`

export const ImgCredit = styled.span`
  color: rgba(255,255,255,.6);
  position: absolute;
  font-size: 0.85rem;
  bottom: 15px;
  right: 15px;
`
