/* eslint react/no-unused-prop-types: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'react-onclickout'
import * as styles from './styles'

const propTypes = {
  component: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
}

const defaultProps = {
  backgroundColor: 'white',
}

function noOp() {}

class HoverMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  onClick = () => {
    this.setState(state => ({
      open: !state.open,
    }))
  }

  onMouseEnter = () => {
    this.setState(() => ({
      open: true,
    }))
  }

  onFocus = () => {
    this.setState(() => ({
      open: true,
    }))
  }

  onBlur = () => {
    this.setState(() => ({
      open: false,
    }))
  }

  onMouseLeave = () => {
    this.setState(() => ({
      open: false,
    }))
  }

  onClickOutside = () => {
    this.setState({ open: false })
  }

  render() {
    const { props, state } = this
    const onClickOut = state.open ? this.onClickOutside : noOp

    const menuContainer = (
      <styles.MenuContainer
        backgroundColor={props.backgroundColor}
      >
        {
          React.Children.map(props.children, (child, index) => {
            // This done to ensure keyboard accessibility in navbar.
            // The behavior isn't optimal but works good enough.
            const key = child.key || index
            if (index === 0) {
              return React.cloneElement(child, { key, onFocus: this.onFocus, first: true })
            }

            if (index === React.Children.count(props.children) - 1) {
              return React.cloneElement(child, { key, onBlur: this.onBlur, last: true })
            }

            return React.cloneElement(child, { key })
          })
        }
      </styles.MenuContainer>
    )
    const clickOutsideMenuContainer = (
      <ClickOutside onClickOut={onClickOut}>
        { menuContainer }
      </ClickOutside>
    )

    return (
      <styles.Container className={props.className} onMouseLeave={this.onMouseLeave}>
        <styles.ComponentContainer onMouseEnter={this.onMouseEnter} onClick={this.onClick}>
          {props.component}
        </styles.ComponentContainer>

        <styles.ChildrenContainer open={state.open}>
          { state.open
            ? clickOutsideMenuContainer
            : menuContainer
          }
        </styles.ChildrenContainer>
      </styles.Container>
    )
  }
}

HoverMenu.propTypes = propTypes
HoverMenu.defaultProps = defaultProps

export default HoverMenu
