import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import * as styles from './styles'
import { Button } from '..'

const propTypes = {
  pricingPlan: PropTypes.object.isRequired
}

export default function PricingCard({ pricingPlan }) {
  return (
    <styles.Container>
      <styles.ImageContainer>
        {pricingPlan.icon}
      </styles.ImageContainer>
      <styles.CardHeader>
        {pricingPlan.header}
      </styles.CardHeader>
      <styles.CardText>
        <p>{pricingPlan.text}</p>
      </styles.CardText>
      <styles.PriceContainer>
        <styles.PriceText>{pricingPlan.price.text}</styles.PriceText>
        {pricingPlan.price.moreInfo && (
          <styles.PriceInfo>{pricingPlan.price.moreInfo}</styles.PriceInfo>
        )}
      </styles.PriceContainer>
      <a href={pricingPlan.button.url} target="_blank" rel="noreferrer noopener">
        <Button size="small">
          {pricingPlan.button.text}
        </Button>
      </a>
      <styles.FeatureContainer>
        {_.map(pricingPlan.features, (feature, i) => {
          return pricingPlan.id === 'enterprise' ? (
            <styles.BigPlan key={i}>
              <styles.BigPlanText>{feature.text}</styles.BigPlanText>
            </styles.BigPlan>
          ) : (
            <styles.Feature key={i}>
              <styles.CheckIcon />
              <div>
                <styles.FeatureText>{`${feature.text} `}</styles.FeatureText>
                {feature.moreInfo && <styles.FeatureInfo>{feature.moreInfo}</styles.FeatureInfo>}
                {feature.explain && (
                  <React.Fragment>
                    <styles.InfoIcon data-tip="react-tooltip" />
                    <ReactTooltip type="dark" effect="solid" clickable backgroundColor="rgba(0, 0, 0, 0.9)">
                      <span>{feature.explain}</span>
                    </ReactTooltip>
                  </React.Fragment>
                )}
              </div>
            </styles.Feature>
          )
        })}
      </styles.FeatureContainer>
    </styles.Container>
  )
}

PricingCard.propTypes = propTypes
