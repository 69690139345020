import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Col } from 'react-styled-flexboxgrid'
import * as styles from './styles'
import { QuoteCard } from '../index'


const propTypes = {
  data: PropTypes.object.isRequired
}

const QuotesBlock = ({ data }) => {
  return (
    <styles.Quotes>
      <styles.QuotesRow>
        {_.map(data.quotes, (quote, i) => {
          return (
            <Col sm={6} xs={12} key={i}>
              <QuoteCard
                key={i}
                data={quote}
                color={data.color}
                bubbleColor={data.bubbleColor}
              />
            </Col>
          )
        })}
      </styles.QuotesRow>
    </styles.Quotes>
  )
}

QuotesBlock.propTypes = propTypes

export default QuotesBlock
