/* eslint react/no-unused-prop-types: "off" */
import React from 'react'
import Color from 'color'
import { Link, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Collapse } from 'react-collapse'
import { MediaQuery, HoverMenuItem } from '..'
import * as styles from './styles'
import variables from '../../styles/variables'
import logo from '../../../assets/aito-logo.svg'
import closeIcon from '../../../assets/icons/close.svg'

const burgerMenuStyles = (barColor) => {
  return {
    bmBurgerButton: {
      position: 'fixed',
    },
    bmCrossButton: {
      height: '32px',
      width: '32px',
      top: '20px',
      right: '30px',
      padding: '20px',
    },
    bmCross: {
      height: '36px',
      width: '36px',
    },
    bmBurgerBars: {
      height: '4px',
      background: barColor,
    },
  }
}

const navItems = [
  {
    id: 0,
    menu: 'Use cases',
    type: 'link',
    url: '/showcases/',
  },
  {
    id: 1,
    menu: 'Docs',
    type: 'link',
    url: '/docs/',
  },
  {
    id: 2,
    menu: 'Pricing',
    type: 'link',
    url: '/pricing/',
  },
  {
    id: 3,
    menu: 'Blog',
    type: 'link',
    url: '/blog/',
  },
  {
    id: 4,
    menu: 'Login',
    type: 'link',
    url: 'https://console.aito.ai/',
    isExternal: true,
  },
  {
    id: 5,
    menu: 'Start for free',
    type: 'button',
    url: '/sign-up/',
  },
]

const HoverIntegrations = ({ slideOut, showSlideOut, items }) => (
  <li>
    <styles.HoverMenu
      slideOut={slideOut}
      showSlideOut={showSlideOut}
      backgroundColor={
        slideOut
          ? Color(variables.colors.pearlBrush.light)
            .lighten(0.05)
            .string()
          : variables.colors.pearlBrush.light
      }
      component={
        <styles.Padded>
          <styles.ExpandableMenuItem>
            Integrations
            <styles.ExpandableMenuItemChevron />
          </styles.ExpandableMenuItem>
        </styles.Padded>
      }
    >
      {_.map(_.sortBy(items, l => l.node.frontmatter.name), (link) => {
        return (
          <HoverMenuItem
            external={false}
            key={link.node.frontmatter.path}
            to={link.node.frontmatter.path}
          >
            {link.node.frontmatter.name}
          </HoverMenuItem>
        )
      })}
    </styles.HoverMenu>
  </li>
)

const BurgerIntegrations = ({ items, id, toggleCollapseMenu, openMenu }) => (
  <div>
    <styles.BurgerMenuHeader
      onClick={() => toggleCollapseMenu(id)}
    >
      Integration
      <styles.Chevron
        direction={openMenu ? 'up' : 'down'}
      />
    </styles.BurgerMenuHeader>
    <Collapse isOpened={openMenu}>
      {_.map(_.sortBy(items, l => l.node.frontmatter.name), (link) => {
        return (
          <styles.BurgerMenuLinkSmall
            external={false}
            key={link.node.frontmatter.path}
            to={link.node.frontmatter.path}
          >
            {link.node.frontmatter.name}
          </styles.BurgerMenuLinkSmall>
        )
      })}
    </Collapse>
  </div>
)

const Integrations = ({
  slideOut, showSlideOut, hover, id, toggleCollapseMenu, openMenu
}) => (
  <StaticQuery
    query={graphql`
        query IntegrationsPaths {
          allMdx(filter: {frontmatter: { path: { regex: "/.*integration.*/" } }}) {
            edges {
              node {
                frontmatter {
                  name
                  path
                }
              }
            }
          }
        }
      `}
    render={(data) => {
      const items = data.allMdx.edges
      return (
        hover
          ? <HoverIntegrations slideOut={slideOut} showSlideOut={showSlideOut} items={items} />
          : <BurgerIntegrations
              items={items}
              id={id}
              toggleCollapseMenu={toggleCollapseMenu}
              openMenu={openMenu}
          />
      )
    }
    }
  />
)

const propTypes = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  slideOut: PropTypes.bool,
  showSlideOut: PropTypes.bool,
}

const defaultProps = {
  background: '',
  textColor: variables.colors.silverTree.medium,
  slideOut: false,
  showSlideOut: false,
}

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenuId: null,
      isOpen: false,
    }
  }

  toggleCollapseMenu = (menuId) => {
    this.setState((prevState) => {
      if (prevState.openMenuId !== menuId) {
        return {
          openMenuId: menuId,
          isOpen: true,
        }
      }
      return {
        isOpen: !prevState.isOpen,
      }
    })
  }

  render() {
    const { openMenuId, isOpen } = this.state
    const { background, textColor, slideOut, showSlideOut } = this.props

    return (
      <nav className="NavBar">
        <styles.Container
          backgroundColor={background}
          slideOut={slideOut}
          showSlideOut={showSlideOut}
        >
          <styles.SubContainer>
            <styles.LogoContainer>
              <Link to="/">
                <styles.Logo src={logo} alt="Aito" />
              </Link>
            </styles.LogoContainer>

            <MediaQuery query={variables.media.md}>
              {(matches) => {
                if (matches) {
                  return (
                    <styles.LinkWrapper textColor={textColor}>
                      <styles.LinkList textColor={textColor}>
                        {_.map(navItems, (item, index) => {
                          if (item.type === 'integration') {
                            return (
                              <Integrations
                                slideOut={slideOut}
                                showSlideOut={showSlideOut}
                                key={index}
                                hover
                              />
                            )
                          }

                          if (item.type === 'hover') {
                            return (
                              <li key={index}>
                                <styles.HoverMenu
                                  slideOut={slideOut}
                                  showSlideOut={showSlideOut}
                                  backgroundColor={
                                    slideOut
                                      ? Color(variables.colors.pearlBrush.light)
                                        .lighten(0.05)
                                        .string()
                                      : variables.colors.pearlBrush.light
                                  }
                                  component={
                                    <styles.Padded>
                                      <styles.ExpandableMenuItem>
                                        {item.menu}
                                        <styles.ExpandableMenuItemChevron />
                                      </styles.ExpandableMenuItem>
                                    </styles.Padded>
                                  }
                                >
                                  {_.map(item.links, (link, i) => {
                                    return (
                                      <HoverMenuItem
                                        external={link.external}
                                        key={i}
                                        to={link.url}
                                      >
                                        {link.text}
                                      </HoverMenuItem>
                                    )
                                  })}
                                </styles.HoverMenu>
                              </li>
                            )
                          }

                          if (item.type === 'button') {
                            return (
                              <li key={index}>
                                <Link to={item.url}>
                                  <styles.Button
                                    type="outline"
                                    buttonColor={textColor}
                                    textColor={textColor}
                                    size="small"
                                  >
                                    {item.menu}
                                  </styles.Button>
                                </Link>
                              </li>
                            )
                          }
                          return (
                            <li key={index}>
                              {item.isExternal ? (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <styles.Padded>{item.menu}</styles.Padded>
                                </a>
                              ) : (
                                <Link to={item.url}>
                                  <styles.Padded>{item.menu}</styles.Padded>
                                </Link>
                              )}
                            </li>
                          )
                        })}
                      </styles.LinkList>
                    </styles.LinkWrapper>
                  )
                }

                return (
                  <div>
                    <span>
                      <styles.BurgerMenu
                        customCrossIcon={<img src={closeIcon} alt="close" />}
                        styles={burgerMenuStyles(textColor)}
                        right
                      >
                        {_.map(navItems, (item, index) => {
                          if (item.type === 'integration') {
                            const openMenu = item.id === openMenuId && isOpen
                            return (
                              <Integrations
                                key={item.id}
                                toggleCollapseMenu={this.toggleCollapseMenu}
                                openMenu={openMenu}
                                id={item.id}
                              />
                            )
                          }
                          if (item.type === 'hover') {
                            const openMenu = item.id === openMenuId && isOpen
                            return (
                              <div key={index}>
                                <styles.BurgerMenuHeader
                                  onClick={() => this.toggleCollapseMenu(item.id)}
                                >
                                  {item.menu}
                                  <styles.Chevron direction={openMenu ? 'up' : 'down'} />
                                </styles.BurgerMenuHeader>
                                <Collapse isOpened={openMenu}>
                                  {_.map(item.links, (link, i) => {
                                    return (
                                      <styles.BurgerMenuLinkSmall
                                        external={link.isExternal}
                                        to={link.url}
                                        key={i}
                                      >
                                        {link.text}
                                      </styles.BurgerMenuLinkSmall>
                                    )
                                  })}
                                </Collapse>
                              </div>
                            )
                          }
                          return (
                            <styles.BurgerMenuLink
                              external={item.isExternal}
                              to={item.url}
                              key={index}
                            >
                              {item.menu}
                            </styles.BurgerMenuLink>
                          )
                        })}
                      </styles.BurgerMenu>
                    </span>
                  </div>
                )
              }}
            </MediaQuery>
          </styles.SubContainer>
        </styles.Container>
      </nav>
    )
  }
}

NavBar.propTypes = propTypes
NavBar.defaultProps = defaultProps
