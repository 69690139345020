import styled from 'styled-components'
import { Button, Input } from '../index'
import variables from '../../styles/variables'

export const RequestAccessFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 450px;
  max-width: 100%;
  form {
    width: 100%;
  }
`

export const CustomForm = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`

export const CustomStyledInput = styled(Input)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  height: 100%;
`

export const CustomStyledFormButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 100%;
  margin: 0;
`
export const DisclaimerText = styled.p`
  font-size: 0.8rem;
  color: ${variables.colors.silverTree.medium};
`
