import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles'

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

const defaultProps = {
  className: '',
  placeholder: ''
}

class Textarea extends React.Component {
  render() {
    const { props } = this
    return (
      <styles.Textarea {...props} onClick={this.onClick}>
        {props.children}
      </styles.Textarea>
    )
  }
}

Textarea.propTypes = propTypes
Textarea.defaultProps = defaultProps

export default Textarea
