import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-styled-flexboxgrid'
import { AnimationWrapper } from '..'
import system10 from '../../../assets/system10.svg'
import * as styles from './styles'

const propTypes = {
  content: PropTypes.object.isRequired
}

const SignUpPageHeader = (props) => {
  const { content } = props
  return (
    <styles.IntroContainer>
      <styles.IntroSection>
        <Row>
          <Col xs={12} md={6}>
            <styles.IntroHeader>
              { content.header }
            </styles.IntroHeader>
            <styles.IntroTagline>
              { content.signUpInfo }
            </styles.IntroTagline>
            <styles.ButtonContainer>
              <a href={content.signUpButton.url} target="_blank" rel="noopener noreferrer">
                <styles.Button>{content.signUpButton.text}</styles.Button>
              </a>
            </styles.ButtonContainer>
            <styles.ButtonInfo>{content.signUpButton.info}</styles.ButtonInfo>
          </Col>
          {content.animation ? <AnimationWrapper path={content.animation.publicURL} />
            : (
              <Col xs={12} md={5}>
                <styles.ConsoleImage
                  fluid={content.consoleImage.childImageSharp.fluid}
                  alt="console"
                  width="100%"
                />
              </Col>
            )
          }

          <Col xs={0} md={1} />
        </Row>
      </styles.IntroSection>

      <styles.IntroSectionSideSquare src={system10} alt="" />
      <styles.IntroSectionBottomSquare src={system10} alt="" />
    </styles.IntroContainer>
  )
}

SignUpPageHeader.propTypes = propTypes

export default SignUpPageHeader
