import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { ChevronDown } from '@styled-icons/octicons'
import variables from '../../styles/variables'
import { TextWrapper } from '../../styles/base'
import { headerStyles } from '../../styles/global'
import { Heading } from '..'
import plusPattern from '../../../assets/plus-pattern.svg'

export const Container = styled.div`
  width: 100%;
  background-image: url(${plusPattern});
  padding: 40px 0;
`

export const SectionContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
`

export const SectionRow = styled(Row)`
  width: 100%;
`
export const HeaderCol = styled(Col)`
  @media ${variables.media.md} {
    border-right: 2px solid ${variables.colors.oracle.light};
    padding-right: 100px;
  }
`
export const QuestionCol = styled(Col)`
  @media ${variables.media.md} {
    padding-left: 100px;
  }
`
export const MailTo = styled.a`
  color: ${variables.colors.jade.medium};
`
export const SectionHeader = styled(Heading)`
  ${headerStyles.headerMedium};
  font-weight: normal;
`
export const SectionText = styled.p`
  margin-bottom: 20px;

  a {
    color: ${variables.colors.jade.medium};
  }
`

export const Centerer = styled.div`
  display: flex;
  justify-content: center;
`

export const QuestionsContainer = styled(TextWrapper)`
  position: relative;
  padding: 0 !important;
`

export const QuestionHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  color: ${variables.colors.oracle.darker};
  max-width: 450px;
  padding-right: 30px;
  margin-bottom: 0 !important;
  font-weight: 500;
`

export const AnswerText = styled.div`
  color: ${variables.colors.oracle.darker};
  max-width: 400px;
  padding-right: 30px;
  margin: 0;

  a, a:hover, a:visited {
    text-decoration: underline;
  }
`

export const Chevron = styled(ChevronDown)`
  width: 18px;
  height: 18px;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  
  ${props => (props.isItemActive ? `
    transform: rotateZ(180deg);
  ` : `
    transform: rotateZ(0);
  `)}
`

export const QuestionHeaderContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

export const QuestionWrapper = styled.div`
  margin-bottom: 30px;
`
