import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import variables from '../../styles/variables'
import { Heading } from '..'
import { headerStyles } from '../../styles/global'
import { TextWrapper } from '../../styles/base'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`

export const SubContainer = styled(TextWrapper)`
  color: ${variables.colors.oracle.dark};
  padding: 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${variables.media.sm} {
    padding: 60px 40px;
    display: block;
  }

  @media ${variables.media.md} {
    padding: 60px 120px;
    display: block;
  }
`

export const BlockHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  font-weight: semibold;
  margin-bottom: 40px;
  @media ${variables.media.sm} {
    margin-bottom: 50px;
  }
`
export const ItemHeader = styled(Heading)`
  ${headerStyles.headerXXSmall};
  color: ${variables.colors.oracle.darker};
  font-family: ${variables.fonts.body};
`
export const ItemText = styled.div`
  color: ${variables.colors.oracle.darker};
`
export const ItemCol = styled(Col)`
  padding-bottom: 30px;
`

export const ItemContainer = styled.div`
  height: 100%;
  @media ${variables.media.md} {
    margin-right: 40px;
  }
  ${props => (props.border ? `
    border: 1px solid ${variables.colors.sunsetOrange.lighter};
    border-radius: 6px;
    padding: 20px;
  ` : `
    padding: 0;
  `)}
`
